import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { setStorage } from "../../../common/storage";
import {
  $mysaMegenta,
  $mysaRed,
  $mysaTeal,
  $mysaYellow,
  mysaTableTheme,
  overlayTriggerDelay,
  routingButton,
  tableStyles,
} from "../../../common/components-style";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";
import DataTable, { createTheme } from "react-data-table-component";
import {
  PropertyAuditStatus,
  PropertyStatus,
  UserAccess,
  UserGroups,
} from "../../../common/constants";
import {
  canBrandPropertyModuleWrite,
  getLoggedUserType,
} from "../../../common/functions";
import Archive from "../../../images/icons/svg/outside/Icon-Function-Archive.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

////////////////////// table theme setting ////////////////////////////////////
createTheme("mysaTable", mysaTableTheme, "light");

const PropertyList: React.FC<any> = ({
  properties,
  loadForm,
  refreshProperties,
  propertyId,
  selectedBrand,
  formOpen,
  setFormOpen,
  setPropertyId,
}): JSX.Element => {
  /**
   * global action
   */
  const { updateProperty } = useStoreActions<any>((actions) => ({
    updateProperty: actions.property.updateProperty,
  }));

  /**
   * global state
   */
  const { updatePropertyError, updatePropertySuccess } = useStoreState<any>(
    (state) => ({
      updatePropertyError: state.property.updatePropertyError,
      updatePropertySuccess: state.property.updatePropertySuccess,
    })
  );

  /**
   * local state
   */
  const [archivePopop, setArchivePopop] = useState<boolean>(false);
  const [propertyStatus, setPropertyStatus] = useState<string>("");
  const [propertyArchive, setPropertyArchive] = useState<any>("");
  const [data, setData] = useState<any>([]);

  /**
   * update state
   */
  const onPropertyArchive = useCallback(
    (property) => {
      updateProperty(property);
    },
    [updateProperty]
  );

  /**
   * manage state
   */
  useEffect(() => {
    if (updatePropertySuccess) {
      toast.success("Property archived successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      refreshProperties();
    }

    if (updatePropertyError) {
      toast.error(updatePropertyError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [refreshProperties, updatePropertyError, updatePropertySuccess]);

  useEffect(() => {
    let temData: any = [];

    if (propertyStatus.length > 1) {
      temData = _.filter(properties.filterProperties, (property) => {
        if (propertyStatus === property.propertyStatus) {
          return {
            _id: property._id,
            brand: property.brandId.name,
            city: property.city,
            propertyName: property.propertyName,
            status: property.status,
            country: property.country,
            propertyStatus: property.propertyStatus,
            externalId: property.externalId,
            brandId: property.brandId._id,
            auditStatus: property.auditStatus,
          };
        }
      });
    } else {
      temData = _.filter(properties.filterProperties, (property) => {
        if (PropertyStatus.archived !== property.propertyStatus) {
          return {
            _id: property._id,
            brand: property.brandId.name,
            city: property.city,
            propertyName: property.propertyName,
            status: property.status,
            country: property.country,
            propertyStatus: property.propertyStatus,
            externalId: property.externalId,
            brandId: property.brandId._id,
            auditStatus: property.auditStatus,
          };
        }
      });
    }

    setData(temData);
  }, [properties.filterProperties, propertyStatus]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: "Building",
      selector: (row) => row.propertyName,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Status",
      cell: (row) => {
        let color = "";
        if (row.propertyStatus === PropertyStatus.pending) color = $mysaYellow;
        if (row.propertyStatus === PropertyStatus.approved) color = $mysaTeal;
        if (row.propertyStatus === PropertyStatus.awaiting)
          color = $mysaMegenta;
        if (row.propertyStatus === PropertyStatus.review) color = $mysaYellow;
        if (row.propertyStatus === PropertyStatus.archived) color = $mysaRed;
        return (
          <span
            style={{ color: color, fontWeight: "bold" }}
            className="text-capitalize"
          >
            {row.propertyStatus}
          </span>
        );
      },
    },
    {
      name: "Audit Status",
      cell: (row) => {
        let color = "";
        let text = "";
        if (row.auditStatus === PropertyAuditStatus.NOT_STARTED) {
          color = $mysaMegenta;
          text = "Not Started";
        }
        if (row.auditStatus === PropertyAuditStatus.CERTIFIED) {
          color = $mysaTeal;
          text = "Certified";
        }
        if (row.auditStatus === PropertyAuditStatus.IN_PROGRESS) {
          color = $mysaYellow;
          text = "In Progress";
        }

        if (row.auditStatus === PropertyAuditStatus.DECLINE) {
          color = $mysaRed;
          text = "Decline";
        }
        if (row.auditStatus === PropertyAuditStatus.PENDING_APPROVAL) {
          color = $mysaYellow;
          text = "Pending Approval";
        }
        if (row.auditStatus === PropertyAuditStatus.AWAITING_APPROVAL) {
          color = $mysaYellow;
          text = "Awaiting Approval";
        }
        if (row.auditStatus === PropertyAuditStatus.APPROVE) {
          color = $mysaTeal;
          text = "Approved";
        }
        return (
          <span
            style={{ color: color, fontWeight: "bold" }}
            className="text-capitalize"
          >
            {text}
          </span>
        );
      },
    },
    {
      name: "Action",
      button: true,
      cell: (row: any) => {
        // let active = (row.status === 'active') ? 1 : 0;

        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">View / Edit</Tooltip>}
            >
              <img
                className="iconSizes"
                src={ViewEdit}
                alt=""
                onClick={() => {
                  loadForm(row._id, row.externalId);
                  setStorage("myo_selected_property", row);
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">Archive</Tooltip>}
            >
              <img
                className="iconSizes"
                src={Archive}
                alt=""
                onClick={() => {
                  const data = {
                    id: row?._id,
                    propertyStatus: PropertyStatus.archived,
                  };
                  setPropertyArchive(data);
                  setArchivePopop(true);
                }}
              />
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-2">
        {getLoggedUserType() === UserGroups.super_admin && (
          <>
            <Row className="btn_wrapper mb-2">
              <Col xs={2}>
                {/* <Select
                className='select_btn'
                isClearable
                placeholder={<div>Select property status</div>}
                options={[
                  {
                    value: PropertyStatus.pending,
                    label: 'Pending',
                  },
                  {
                    value: PropertyStatus.approved,
                    label: 'Approved',
                  },
                  {
                    value: PropertyStatus.awaiting,
                    label: 'Awaiting',
                  },
                  {
                    value: PropertyStatus.review,
                    label: 'Review',
                  },
                  {
                    value: PropertyStatus.archived,
                    label: 'Archive',
                  }
                ]}
                styles={selectStyles}
                isSearchable={false}
                onChange={(value) => { setPropertyStatus(value?.value ? value.value : ''); }}
              /> */}
              </Col>
              <Col xs={8}></Col>
              <Col xs={2}>
                {canBrandPropertyModuleWrite(
                  selectedBrand?.key,
                  propertyId,
                  UserAccess.property_access.details.code
                ) && (
                    <Button
                      hidden={formOpen}
                      style={routingButton}
                      id="routing-button"
                      onClick={() => {
                        setFormOpen(true);
                        setPropertyId(null);
                      }}
                      className="float-right mb-3"
                    >
                      <b>Create New Property</b>
                    </Button>
                  )}
              </Col>
            </Row>
          </>
        )}
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme="mysaTable"
          fixedHeader={false}
          noDataComponent={"Updating records"}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[10, 50, 100, 200]}
        />
      </div>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes archive it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you wish to archive this property?"
        onConfirm={() => {
          onPropertyArchive(propertyArchive);
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      ></SweetAlert>
    </>
  );
};

export default PropertyList;
