import { FC, useState, useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { tableStyles } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';




const UnmatchedPropertyData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [csvAllData, setCsvData] = useState<Array<any>>([]);
    const [clearDataEnabled, setClearDataEnabled] = useState<boolean>(false);



    const { getAllHotelTempData, deleteHotelsTempData, getAllHotelPaginationTempData } = useStoreActions<any>((actions) => ({
        getAllHotelTempData: actions.hotel.getAllHotelTempData,
        deleteHotelsTempData: actions.hotel.deleteHotelsTempData,
        getAllHotelPaginationTempData: actions.hotel.getAllHotelPaginationTempData
    }));

    /**
     * global state
     */
    const { getHotelsTempDataSuccess, getHotelsPaginationTempDataSuccess } = useStoreState<any>((state) => ({
        getHotelsTempDataSuccess: state.hotel.getHotelsTempDataSuccess,
        getHotelsTempDataError: state.hotel.getHotelsTempDataError,
        deleteHotelsTempDataSuccess: state.hotel.deleteHotelsTempDataSuccess,
        getHotelsPaginationTempDataSuccess: state.hotel.getHotelsPaginationTempDataSuccess,

    }));


    // const ButtonStyle = {
    //     ...loginButton,
    //     color: $mysaBlack,
    //     fontWeight: 'bold'
    // } as React.CSSProperties;

    const getLoadAllTempHotels = useCallback(() => {
        const payload = {
            page: page,
            perPage: perPage,
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
        };
        getAllHotelTempData(payload);
    }, [selectedClient, getAllHotelTempData, page, perPage, programmeId])

    useEffect(() => {
        getLoadAllTempHotels();
    }, [getLoadAllTempHotels]);

    const handleDownloadStart = useCallback(() => {
        setClearDataEnabled(false);
        const payload = {
            page: 0,
            perPage: 0,
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
        };
        try {
            const res = getAllHotelPaginationTempData(payload);
            if (res?.status && res?.data?.data.length > 0) {
                const csvTempData = res?.data?.data.map((row) => ({
                    brandName: row.brandName || '',
                    hotelName: row.hotelName || '',
                    address: row.address || '',
                    city: row.city || '',
                    state: row.state || '',
                }));
                setCsvData(csvTempData);
                startDownloading(res?.data?.data);
            }
        } catch (error) {
            toast.error("Download Failed!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
        }
    }, [getAllHotelPaginationTempData, programmeId, selectedClient?._id, selectedClient?.key]);
    useEffect(() => {
        handleDownloadStart();
    }, [handleDownloadStart]);


    useEffect(() => {
        if (getHotelsTempDataSuccess?.data) {
            setData(getHotelsTempDataSuccess?.data?.data || []);
            setFirstLoading(false);
            setTableLoading(false);
            setLoading(false);
            setTotalRows(getHotelsTempDataSuccess.data.total);
        }

    }, [getHotelsTempDataSuccess]);



    const getLoadPaginationTempHotels = useCallback(() => {
        const payload = {
            page: page,
            perPage: perPage,
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
        };
        getAllHotelPaginationTempData(payload);
    }, [selectedClient, getAllHotelPaginationTempData, page, perPage, programmeId])

    useEffect(() => {
        getLoadPaginationTempHotels();
    }, [getLoadPaginationTempHotels]);


    useEffect(() => {
        if (getHotelsPaginationTempDataSuccess?.data) {
            setCsvData(getHotelsPaginationTempDataSuccess?.data?.data || []);
        }

    }, [getHotelsPaginationTempDataSuccess]);

    const handlePageChange = page => {
        setTableLoading(true);
        setPage(page);
    };
    const handlePerRowsChange = async (perPage, page) => {
        setTableLoading(true);
        setPage(page);
        setPerPage(perPage);
    };

    const csvHeaders = [
        { label: "Brand", key: "brandName" },
        { label: "Hotel", key: "hotelName" },
        { label: "Address", key: "address" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
    ];



    const startDownloading = (record: any[]) => {

        if (record?.length > 0) {
            const csvData = record.map((row) => ({
                brandName: row.brandName || '',
                hotelName: row.hotelName || '',
                address: row.address || '',
                city: row.city || '',
                state: row.state || '',
            }));
        }
    }




    const initialDownload = () => {
        setTimeout(() => {
            setClearDataEnabled(true);
            toast.success("File downloaded successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);

        }, 2000);
    }

    const handleClearData = async () => {
        setLoading(true);
        setClearDataEnabled(false);

        const payload = {
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
        };

        try {
            await deleteHotelsTempData(payload);

            toast.success("Data cleared successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            setData([]);
            setTotalRows(0);

            setLoading(false);
            setClearDataEnabled(true);
        } catch (error) {
            toast.error("Failed to clear data!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'Brand',
            selector: row => row.brandName,
        },
        {
            name: 'Hotel',
            selector: row => row.hotelName,
        },
        {
            name: 'Address',
            selector: row => row.address,
        },
        {
            name: 'City',
            selector: row => row.city,
        },
        {
            name: 'State',
            selector: row => row.state,
        },
    ];

    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="programmeSpendReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                        />
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Button
                        onClick={handleClearData}
                        className="btn_design customClearDataBtn"
                        disabled={!clearDataEnabled}
                    >
                        Clear Data
                    </Button>
                    <CSVLink
                        data={csvAllData}
                        headers={csvHeaders}
                        filename={`${reportName || "report"}.csv`}
                        className="h-10 ml-4 customDeleteBtn"
                        target="_blank"
                        onClick={initialDownload}
                    >
                        {loading ? "Downloading..." : "Download"}
                    </CSVLink>
                </div>
            </div>

            {

                !firstLoading && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={tableLoading}
                        pagination
                        paginationServer
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        noDataComponent={'Updating records'}
                    />
                </div>
            }
        </>
    )
}

export default UnmatchedPropertyData;