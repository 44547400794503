import { Form, Col, Row, Button } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from 'react-toastify';
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';


const UploadPropertyData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName }): JSX.Element => {
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {

        getCorporateProgrammeById,
        createHotelTempData
    } = useStoreActions<any>((actions) => ({

        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        createHotelTempData: actions.hotel.createHotelTempData,
    }))

    const {

        getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError,
        createHotelTempDataSuccess,
        createHotelTempDataError

    } = useStoreState<any>((state) => ({

        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,
        createHotelTempDataSuccess: state.hotel.createHotelTempDataSuccess,
        createHotelTempDataError: state.hotel.createHotelTempDataError,

    }));
    // set the programme id if exists ////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        setSelectedProgrammeId(programmeId);
    }, [programmeId]);


    // setting the programme data to the local state ///////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess?.data) {
            const { clientId, programmeName, _id } = getCorporateProgrammeByIdSuccess?.data;
            setClientId(clientId._id);
            setSelectedClientName(clientId.companyName);
            setSelectedProgrammeId(_id);
            setSelectedProgrammeName(programmeName);
        }
        if (getCorporateProgrammeByIdError) {
            toast.error(getCorporateProgrammeByIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }
    }, [getCorporateProgrammeByIdError, getCorporateProgrammeByIdSuccess?.data, reportId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (selectedProgrammeId) {
            getCorporateProgrammeById(selectedProgrammeId);
        }
    }, [getCorporateProgrammeById, selectedProgrammeId]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const requiredHeaders = ["brand", "hotel", "address", "city", "state"];

    const validateHeaders = (headers) => {
        const normalizedHeaders = headers.map((header) => header.toLowerCase());
        return requiredHeaders.every((requiredHeader) =>
            normalizedHeaders.includes(requiredHeader)
        );
    };


    const callApi = useCallback((user) => {
        if (user) {
            setIsLoading(true);
            let newData = user.map(obj => {
                let newObj = {};
                for (let key in obj) {
                    let newKey = key.replace(/\s+/g, '').toLowerCase();
                    newObj[newKey] = obj[key];
                }
                return newObj;
            });

            const validData = newData.filter(data =>
                data.brand?.trim() || data.hotel?.trim() || data.address?.trim() || data.city?.trim() || data.state?.trim()
            );


            validData.forEach((data, index) => {
                const payload = {
                    clientId: clientId,
                    programmeId: programmeId,
                    ...(data.brand?.trim() ? { brandName: data.brand.trim() } : {}),
                    ...(data.hotel?.trim() ? { hotelName: data.hotel.trim() } : {}),
                    ...(data.address?.trim() ? { address: data.address.trim() } : {}),
                    ...(data.city?.trim() ? { city: data.city.trim() } : {}),
                    ...(data.state?.trim() ? { state: data.state.trim() } : {}),
                };
                if (Object.keys(payload).length > 2) {
                    setTimeout(() => {
                        createHotelTempData(payload)
                            .then(() => {
                                if (index === validData.length - 1) {
                                    toast.success("File uploaded successfully!", {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        className: 'foo-bar'
                                    });
                                    setIsLoading(false);
                                }
                            })
                            .catch(() => {
                                toast.error("Error uploading data. Please try again.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    className: 'foo-bar'
                                });
                                setIsLoading(false);
                            });
                    }, 1000);
                }
            });
        }
    }, [clientId, programmeId, createHotelTempData]);




    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            const binaryStr = reader.result;

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headers = json[0];
                if (validateHeaders(headers)) {
                    const dataRows = XLSX.utils.sheet_to_json(worksheet);
                    callApi(dataRows);
                } else {
                    console.error('Invalid file format');
                    alert('Invalid file format. Required headers: brand, hotel, address, city, state');
                }

            } else if (file.name.endsWith('.csv')) {
                Papa.parse(binaryStr, {
                    header: true,
                    complete: (results) => {
                        const headers = Object.keys(results.data[0]);
                        if (validateHeaders(headers)) {
                            callApi(results.data);
                        } else {
                            console.error('Invalid file format');
                            alert('Invalid file format. Required headers: brand, hotel, address, city, state');
                        }
                    },
                });
            } else {
                console.error('Unsupported file format');
                alert('Unsupported file format. Please upload a valid .xlsx or .csv file.');
            }
        };
        reader.readAsBinaryString(file);
    }, [callApi]);

    const { open } = useDropzone({ onDrop });

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>

            {reportId &&
                <RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} reportId={reportId} programmeId={programmeId} startDate={reportName}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper" style={{ marginLeft: "40px" }}>
                <Form>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right', }}>
                            <Form.Label>
                                Company Name
                            </Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedClientName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Name of programme</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedProgrammeName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Accommodation type</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{accommodationType}</p>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }} className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>

                        </Col>
                        <Col md={3} >
                            <Button id="create-button" onClick={open} disabled={isLoading}>
                                {isLoading ? 'Uploading...' : 'Upload File'}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </>
    )
}

export default UploadPropertyData;
