import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import { UserAccess, InventoryStatus } from '../../../../common/constants';
import { isPropertyModuleAvailable } from '../../../../common/functions';
import AccessDenied from '../../../common/AccessDenied';
import InventoryAmenities from './InventoryAmenities';
import InventoryDetailsForm from './InventoryDetailsForm';
import InventoryImages from './InventoryImages';


const InventoryForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, inventoryId, propertyId, setFormOpen, selectedProperty, selectedBrand, loadData, selectedInventory }): JSX.Element => {
  const history = useHistory();
  const [tabKey, setTabKey] = useState<any>('details');
  const [isRateAccess, setIsRateAccess] = useState<boolean>(false);
  const [isAvailabilityAccess, setIsAvailabilityAccess] = useState<boolean>(false);
  // const redirectProperty = () => {
  // 	if (isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code)) {
  // 		history.push('/admin/inventory-management', { propertyId: propertyId, selectedBrand: selectedBrand })
  // 	} else {
  // 		setIsAccess(true);
  // 	}

  // }

  const redirectRate = () => {
    if (isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code)) {
      history.push('/admin/rate-management', { allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, propertyId: propertyId, inventoryId: inventoryId, selectedProperty: selectedProperty, selectedBrand: selectedBrand })
    } else {
      setIsRateAccess(true);
    }

  }

  const redirectAvailability = () => {
    if (isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code)) {
      history.push('/admin/availability-management', { allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, propertyId: propertyId, inventoryId: inventoryId, selectedProperty: selectedProperty, selectedBrand: selectedBrand })
    } else {
      setIsAvailabilityAccess(true);
    }
  }
  return (
    <div style={tabDivStyle}>
      <Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
        <Row >
          <Col sm={2}>
            <Nav variant="pills" className="flex-column  secondry-menu" style={navStyle}>
              <div style={{ marginTop: '0%' }}>
                <Nav.Item>
                  <Nav.Link eventKey="details">Inventory Details</Nav.Link>
                </Nav.Item>
                {selectedInventory?.status !== InventoryStatus.INPROGRESS && (
                  <>
                    {propertyId && inventoryId && (
                      <>
                        <Nav.Item><Nav.Link eventKey="inventoryImages">Inventory Images</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="inventoryAmenities">Inventory Amenities</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="RateManagement" onClick={redirectRate}>Rate Management</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="AvailabilityManagement" onClick={redirectAvailability}>Availability Management</Nav.Link></Nav.Item>
                      </>
                    )}
                  </>
                )}
              </div>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content >
              <Tab.Pane eventKey="details" >
                <div style={navContentStyle}>
                  <InventoryDetailsForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} inventoryId={inventoryId} selectedProperty={selectedProperty} selectedBrand={selectedBrand} setFormOpen={setFormOpen} loadData={loadData} />
                </div>
              </Tab.Pane>
              {propertyId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && <Tab.Pane eventKey="inventoryImages">
                <div style={navContentStyle}>
                  <InventoryImages allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} inventoryId={inventoryId} setFormOpen={setFormOpen} selectedProperty={selectedProperty} selectedBrand={selectedBrand} />
                </div>
              </Tab.Pane>}
              {propertyId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && <Tab.Pane eventKey="inventoryAmenities">
                <div style={navContentStyle}>
                  <InventoryAmenities allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} inventoryId={inventoryId} setFormOpen={setFormOpen} selectedProperty={selectedProperty} selectedBrand={selectedBrand} />
                </div>
              </Tab.Pane>}

              {propertyId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && isRateAccess && <Tab.Pane eventKey="RateManagement">
                <div style={navContentStyle}>
                  <AccessDenied />
                </div>
              </Tab.Pane>}
              {propertyId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && isAvailabilityAccess && <Tab.Pane eventKey="AvailabilityManagement">
                <div style={navContentStyle}>
                  <AccessDenied />
                </div>
              </Tab.Pane>}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
};

export default InventoryForm;