import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from "easy-peasy";
import { formButton } from "../../../../common/components-style";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { RfpProcessStatus } from "../../../../common/constants";
import pendingSvg from '../../../../images/icons/svg/pending-icon.svg';
import releasedSvg from '../../../../images/icons/svg/released-icon.svg';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from "react-router-dom";

const selectOptions = [
  { label: 'Select One', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' }
]

const SurveyManagement: React.FC<any> = ({ rfpName, rfpStatus, programmeId, rfpProcessId, selectedClient, selectedProgramme, startDate }): JSX.Element => {
  //////////////////////////////////////////////////////////////////////////////////////////////
  const history = useHistory();
  //////////////////////////////////Local state manage//////////////////////////////////////////
  const [archivePopop, setArchivePopop] = useState<boolean>(false);
  const [checkNoButtonAction, setCheckNoButtonAction] = useState<boolean>(false);
  const [deleteSurvey, setDeleteSurvey] = useState<any>('');
  const [selectedClientName, setSelectedClientName] = useState<string>("");
  const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [enableCreateForm, setEnableCreateForm] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<{
    id: String,
    clientID: String,
    name: String,
    description: String,
    corporate_client: String,
    programme: String,
    rfp_date: String,
    rfpStatus: String,
    rfp_EndDate: String,
    rfpName: String;
    useExpectedCompletionPeriod: Boolean,
    expectedCompletionPeriod: Number,
    external: Boolean,
    surveys?: Array<{
      surveyDescription: String,
      surveyID: String,
      surveyName: String,
      status: String,
      survey_type: String,
      corporate_client: String,
      programme: String,
      rfp_date: String
      rfpStatus: String,
      rfp_EndDate: String,
      rfpName: String;
    }> | []
  }>();
  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [surveyCount, setSurveyCount] = useState<Number>(0);
  //////////// global actions //////////
  const {
    getRfpProcessById,
    getTemplate,
    getCorporateProgrammeById,
    surveyCreateUpdateDelete
  } = useStoreActions<any>((actions) => ({
    getRfpProcessById: actions.rfpProcess.getRfpProcessById,
    getTemplate: actions.myoManagementModel.getTemplate,
    getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
    surveyCreateUpdateDelete: actions.rfpProcess.surveyCreateUpdateDelete,
  }));

  //////// global state ///////
  const {
    rfpProcessByIdSuccess,
    getTemplateSuccess,
    getCorporateProgrammeByIdSuccess,
    getSurveyCreateUpdateDeleteSuccess,
  } = useStoreState<any>((state) => ({
    rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
    getTemplateSuccess: state.myoManagementModel.getTemplateSuccess,
    getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
    getSurveyCreateUpdateDeleteSuccess: state.rfpProcess.getSurveyCreateUpdateDeleteSuccess,
  }));

  ////////////// form validation 
  let JsSchema = Yup.lazy((value) => {
    if (enableCreateForm) {
      return Yup.object().shape({
        needSpecificSurveys: Yup.string().required('Please select one!'),
        surveysCounts: Yup.string().required('Please select one!'),
        surveys: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().optional(),
            names: Yup.string().required('Name of survey required'),
            description: Yup.string().required('Description required'),
            survey_type: Yup.string().required('Survey type required'),
          }),
        ),
      });
    } else {
      return Yup.object().shape({
        needSpecificSurveys: Yup.string().optional(),
        surveysCounts: Yup.string().optional(),
        surveys: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().optional(),
            names: Yup.string().optional(),
            description: Yup.string().optional(),
            survey_type: Yup.string().optional(),
          }),
        ),
      });
    }

  });



  ///////////// initialize form validation
  const optionsDf = { resolver: yupResolver(JsSchema) }
  ////////////////////////////////Declaration//////////////////////////////////////////
  const {
    control,
    formState,
    handleSubmit,
    register,
    watch,
    setValue
  } = useForm(optionsDf);

  //////// destructure formState
  const { errors } = formState;

  //////// dynamic form handler
  const { fields, append, remove } = useFieldArray({ name: 'surveys', control })

  ////////// form add and remove handler
  const surveysCounts = watch('surveysCounts');

  useEffect(() => {
    const currentProp = parseInt(surveysCounts || 0)
    const previousProp = fields.length
    if (currentProp > previousProp) {
      for (let i = previousProp; i < currentProp; i++) {
        append({ name: '' })
      }
    } else {
      for (let i = previousProp; i > currentProp; i--) {
        remove(i - 1)
      }
    }
  }, [append, fields.length, remove, surveysCounts]);

  const enableForm = useCallback((value) => {
    if (value === "Yes") {
      setEnableCreateForm(true);
    } else {
      setEnableCreateForm(false);
    }
  }, []);

  //////////////////////////////////////////

  useEffect(() => {
    if (rfpProcessId) {
      getRfpProcessById(rfpProcessId);
    }
    if (programmeId) {
      getCorporateProgrammeById(programmeId);
    }
  }, [rfpProcessId, programmeId, getRfpProcessById, getCorporateProgrammeById]);

  //////////////////// rfp related useEffect ////////
  useEffect(() => {
    if (rfpProcessByIdSuccess) {
      getTemplate({
        security: {
          apiKey: process.env.REACT_APP_AUDIT_API_KEY,
          user: "superadmin@mysa.global"
        },
        id: rfpProcessByIdSuccess?.data?.certificateTemplateId
      });
    }

    if (getSurveyCreateUpdateDeleteSuccess) {
      if (!checkNoButtonAction) {
        setLoading(false);
        getRfpProcessById(rfpProcessId);
        toast.success("Survey management updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
        setEditEnable(false);
        getRfpProcessById(rfpProcessId);
      } else {
        history.push('/admin/rfp-management', {
          selectedProgramme, selectedClient, programmeId
        })
      }
    }

  }, [rfpProcessByIdSuccess, getSurveyCreateUpdateDeleteSuccess, getTemplate, getRfpProcessById, rfpProcessId, checkNoButtonAction, history, selectedProgramme, selectedClient, programmeId]);

  ////////////////////////// client /////////
  useEffect(() => {
    if (getCorporateProgrammeByIdSuccess?.data) {
      const { clientId, programmeName } = getCorporateProgrammeByIdSuccess.data;

      setSelectedClientName(clientId.companyName);
      setSelectedProgrammeName(programmeName);

    }
  }, [getCorporateProgrammeByIdSuccess]);

  //////////// myo management related useEffect
  useEffect(() => {
    if (getTemplateSuccess) {
      setTemplateData(getTemplateSuccess?.template);
      if (getTemplateSuccess?.template?.surveys?.length !== 0) {
        setEnableCreateForm(true);
        setValue('needSpecificSurveys', 'Yes');
        setValue('surveysCounts', getTemplateSuccess?.template?.surveys?.length);
        setSurveyCount(getTemplateSuccess?.template?.surveys?.length);
      } else {
        setEnableCreateForm(false);
        setValue('surveysCounts', '');
        setValue('surveys', []);
        setSurveyCount(getTemplateSuccess?.template?.surveys?.length);
        setEditEnable(true);
      }

    }

  }, [getTemplateSuccess, setValue]);

  //////////////////////////////////////////From submit manage//////////////////////////////////////////
  const onSubmit = async (values) => {
    setLoading(true);
    if (templateData?.surveys?.length === 0) {

      if (values?.needSpecificSurveys === 'Yes') {
        const payload = values.surveys?.map((value: any) => {
          if (value?.names !== '') {
            return {
              Name: value?.names,
              Description: value?.description ? value?.description : '',
              survey_type: value?.survey_type,
              corporate_client: templateData?.corporate_client,
              programme: templateData?.programme,
              rfp_date: templateData?.rfp_date,
              rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
              rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
              rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
              id: ''
            }
          } else {
            return {};
          }
        });

        const payloadData = {
          survey: payload,
          old_survey: [],
          id: templateData?.id,
          name: templateData?.name,
          description: templateData?.description ? templateData?.description : '',
          corporate_client: templateData?.corporate_client,
          programme: templateData?.programme,
          rfp_date: templateData?.rfp_date,
          rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
          rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
          useExpectedCompletionPeriod: templateData?.useExpectedCompletionPeriod,
          expectedCompletionPeriod: templateData?.expectedCompletionPeriod,
          external: templateData?.external,
          rfpId: rfpProcessId,
          rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
          clientID: process.env.REACT_APP_MYO_CLIENT_ID,
        }

        surveyCreateUpdateDelete(payloadData);

      } else {
        const payloadData = {
          survey: [],
          old_survey: [],
          id: templateData?.id,
          name: templateData?.name,
          description: templateData?.description ? templateData?.description : '',
          corporate_client: templateData?.corporate_client,
          programme: templateData?.programme,
          rfp_date: templateData?.rfp_date,
          rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
          rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
          useExpectedCompletionPeriod: templateData?.useExpectedCompletionPeriod,
          expectedCompletionPeriod: templateData?.expectedCompletionPeriod,
          external: templateData?.external,
          rfpId: rfpProcessId,
          clientID: process.env.REACT_APP_MYO_CLIENT_ID,
          rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
        }

        surveyCreateUpdateDelete(payloadData);
      }

    } else {
      if (values?.needSpecificSurveys === 'Yes') {
        const payload = values.surveys?.map((value) => {
          if (value?.names !== '') {
            return {
              Name: value?.names,
              Description: value?.description ? value?.description : '',
              survey_type: value?.survey_type,
              corporate_client: templateData?.corporate_client,
              programme: templateData?.programme,
              rfp_date: templateData?.rfp_date,
              rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
              rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
              id: value?.id,
              rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
            }
          } else {
            return {};
          }
        });

        const payloadData = {
          survey: payload,
          old_survey: [],
          id: templateData?.id,
          name: templateData?.name,
          description: templateData?.description ? templateData?.description : '',
          corporate_client: templateData?.corporate_client,
          programme: templateData?.programme,
          rfp_date: templateData?.rfp_date,
          rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
          rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
          useExpectedCompletionPeriod: templateData?.useExpectedCompletionPeriod,
          expectedCompletionPeriod: templateData?.expectedCompletionPeriod,
          external: templateData?.external,
          rfpId: rfpProcessId,
          rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
          clientID: process.env.REACT_APP_MYO_CLIENT_ID,
        }

        surveyCreateUpdateDelete(payloadData);

      } else {

        const payload = values.surveys?.map((value) => {
          if (value?.names !== '') {
            return {
              Name: value?.names,
              Description: value?.description ? value?.description : '',
              survey_type: value?.survey_type,
              corporate_client: templateData?.corporate_client,
              programme: templateData?.programme,
              rfp_date: templateData?.rfp_date,
              rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
              rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
              rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
              id: value?.id
            }
          } else {
            return {};
          }
        });

        const payloadData = {
          survey: [],
          old_survey: payload,
          id: templateData?.id,
          name: templateData?.name,
          description: templateData?.description ? templateData?.description : '',
          corporate_client: templateData?.corporate_client,
          programme: templateData?.programme,
          rfp_date: templateData?.rfp_date,
          rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
          rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
          useExpectedCompletionPeriod: templateData?.useExpectedCompletionPeriod,
          expectedCompletionPeriod: templateData?.expectedCompletionPeriod,
          external: templateData?.external,
          rfpId: rfpProcessId,
          rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
          clientID: process.env.REACT_APP_MYO_CLIENT_ID,
        }

        surveyCreateUpdateDelete(payloadData);
      }

    }
  };

  const removeSurvey = async (deleteSurvey) => {
    const deleteSurveys = [{
      Name: deleteSurvey?.surveyName,
      Description: deleteSurvey?.surveyDescription,
      survey_type: deleteSurvey?.survey_type,
      corporate_client: templateData?.corporate_client,
      programme: templateData?.programme,
      rfp_date: templateData?.rfp_date,
      rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
      rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
      rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
      id: deleteSurvey?.surveyID
    }];

    const payload: any = [];
    templateData?.surveys?.forEach((survey) => {
      if (deleteSurvey?.surveyID.toString() !== survey?.surveyID.toString()) {
        payload.push({
          Name: survey?.surveyName,
          Description: survey?.surveyDescription ? survey?.surveyDescription : '',
          survey_type: survey?.survey_type,
          corporate_client: templateData?.corporate_client,
          programme: templateData?.programme,
          rfp_date: templateData?.rfp_date,
          rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
          rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
          rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
          id: survey?.surveyID
        });
      }
    });

    const payloadData = {
      survey: payload,
      old_survey: deleteSurveys,
      id: templateData?.id,
      name: templateData?.name,
      description: templateData?.description ? templateData?.description : '',
      corporate_client: templateData?.corporate_client,
      programme: templateData?.programme,
      rfp_date: templateData?.rfp_date,
      rfp_EndDate: templateData?.rfp_EndDate ? templateData?.rfp_EndDate : '',
      rfpStatus: templateData?.rfpStatus ? templateData?.rfpStatus : '',
      useExpectedCompletionPeriod: templateData?.useExpectedCompletionPeriod,
      expectedCompletionPeriod: templateData?.expectedCompletionPeriod,
      external: templateData?.external,
      rfpId: rfpProcessId,
      rfpName: templateData?.rfpName ? templateData?.rfpName : rfpName,
      clientID: process.env.REACT_APP_MYO_CLIENT_ID,
    }

    surveyCreateUpdateDelete(payloadData);

  }

  return (
    <>
      {rfpProcessId &&
        <RFPBreadCrumbPage isPageName="RFP" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpName}></RFPBreadCrumbPage>
      }

      <div className="ml-auto edit_btn_wpr">{
        !loading && !editEnable && rfpStatus === RfpProcessStatus.NOTSTARTED && <Button id='edit-button' style={formButton} onClick={() => {
          setEditEnable(true);
        }} >Edit</Button>
      }
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "80px" }}>
        <Row className='formSpace'>
          <Col md={4} style={{ textAlign: 'right' }}>
            <Form.Label>{editEnable && <span className='requiredMark'>* </span>}Do you wish to create client specific surveys?</Form.Label>
          </Col>
          {
            !editEnable && <Col md={4} style={{ textAlign: 'left' }}>
              {surveyCount !== 0 ? 'Yes' : 'No'}
            </Col>
          }
          {
            editEnable && <Col md={4} style={{ textAlign: 'left' }}>
              <Form.Check
                inline
                {...register("needSpecificSurveys")}
                value={'Yes'}
                label={'Yes'}
                type="radio"
                onChange={(value) => { enableForm(value.currentTarget.value); }}
              />

              <Form.Check
                inline
                {...register("needSpecificSurveys")}
                value={'No'}
                label={'No'}
                type="radio"
                onChange={(value) => {
                  setCheckNoButtonAction(true);
                  onSubmit(0);
                  enableForm(value.currentTarget.value);
                }}
              />
            </Col>
          }

        </Row>
        {
          enableCreateForm && <div>
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label>{editEnable && <span className='requiredMark'>* </span>}How many surveys do you wish to create?</Form.Label>
              </Col>
              {
                !editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                  {surveyCount}
                </Col>
              }
              {
                editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                  <Form.Select {...register("surveysCounts")} className={`${errors.surveysCounts ? 'is-invalid' : ''}`}>
                    {selectOptions.map((i) => (
                      <option key={i.value} value={i.value} disabled={templateData?.surveys?.length !== 0 ? templateData?.surveys?.length !== undefined && templateData?.surveys?.length >= Number(i.value) ? true : false : false}>
                        {i.label}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback">{errors.surveysCounts?.message}</div>
                </Col>
              }


            </Row>

            {fields.map((item, i) => (
              <div key={i} className="mt-3 mb-2">
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>* </span>}Name of Survey {i + 1} </Form.Label>
                  </Col>
                  {
                    !editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                      <p>{templateData?.surveys?.[i]?.surveyName.toString()}</p>
                    </Col>
                  }

                  {
                    editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                      <Form.Control type="text" defaultValue={templateData?.surveys?.[i]?.surveyName.toString()} className={`${errors.surveys?.[i]?.names ? 'is-invalid' : ''}`} {...register(`surveys.${i}.names`)} />
                      <div className="invalid-feedback">
                        {errors.surveys?.[i]?.names?.message}
                      </div>
                    </Col>
                  }

                  {!editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                    <>
                      {
                        templateData?.surveys?.[i]?.status.toString() === 'PENDING' || templateData?.surveys?.[i]?.status.toString() === 'COMPLETED' ? <Button className="btnCustom" ><img src={pendingSvg} alt={pendingSvg} /></Button> : templateData?.surveys?.[i]?.status === 'RELEASED' && <Button className="btnCustom" ><img src={releasedSvg} alt={releasedSvg} /></Button>
                      }
                      {rfpStatus === RfpProcessStatus.NOTSTARTED && templateData?.surveys?.[i]?.status && <Button onClick={() => {
                        setArchivePopop(true); setDeleteSurvey(templateData?.surveys?.[i]);
                      }} style={{ marginLeft: '3%' }} className="btnCustom" >Remove</Button>}
                    </>
                  </Col>
                  }

                </Row>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> {editEnable && <span className='requiredMark'>* </span>} Description</Form.Label>
                  </Col>
                  {
                    !editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                      <p>{templateData?.surveys?.[i]?.surveyDescription.toString()}</p>
                    </Col>
                  }
                  {
                    editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                      <Form.Control as={'textarea'} defaultValue={templateData?.surveys?.[i]?.surveyDescription.toString()} rows={6} className={`${errors.surveys?.[i]?.description ? 'is-invalid' : ''}`} {...register(`surveys.${i}.description`)} />
                      <div className="invalid-feedback">
                        {errors.surveys?.[i]?.description?.message}
                      </div>
                    </Col>
                  }

                </Row>
                <Form.Control hidden={true} value={templateData?.surveys?.[i]?.surveyID.toString()} defaultValue={templateData?.surveys?.[i]?.surveyID.toString()}  {...register(`surveys.${i}.id`)} />
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    {
                      !editEnable && <Form.Label>Survey Type</Form.Label>
                    }
                  </Col>
                  {
                    !editEnable && <Col md={4} style={{ textAlign: 'left' }}>
                      {templateData?.surveys?.[i]?.survey_type === 'BRAND_SURVEY' ? 'Brand Survey' : 'Property Survey'}
                    </Col>
                  }

                  {
                    editEnable && <Col md={8} style={{ textAlign: 'left' }}>
                      <Form.Check
                        inline
                        {...register(`surveys.${i}.survey_type`)}
                        value={'BRAND_SURVEY'}
                        label={'Brand Survey'}
                        type="radio"
                        defaultChecked={templateData?.surveys?.[i]?.survey_type === 'BRAND_SURVEY' ? true : false}
                      />

                      <Form.Check
                        inline
                        {...register(`surveys.${i}.survey_type`)}
                        value={'PROPERTY_SURVEY'}
                        label={'Property Survey'}
                        type="radio"
                        defaultChecked={templateData?.surveys?.[i]?.survey_type === 'PROPERTY_SURVEY' ? true : false}
                      />

                      <div className="invalid-feedback">
                        {errors.surveys?.[i]?.survey_type?.message}
                      </div>
                    </Col>
                  }

                </Row>
              </div>
            ))}
          </div>
        }
        <Row className='formSpace'>
          <Col md={4} style={{ textAlign: 'right' }}>
          </Col>
          <Col md={8} style={{ textAlign: 'left' }}>
            {
              !loading && editEnable && rfpStatus === RfpProcessStatus.NOTSTARTED && <>
                <Button type="submit" id='approve-button' style={formButton} >Update</Button>
              </>
            }
            {
              !loading && !editEnable && rfpStatus === RfpProcessStatus.NOTSTARTED && <Button id='edit-button' style={formButton} onClick={() => {
                setEditEnable(true);
              }} >Edit</Button>
            }
            {
              loading && <Button id='approve-button' style={formButton}><Spinner animation="border" variant="warning" /></Button>
            }
          </Col>
        </Row>
      </Form>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes remove it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to remove this survey definition?"
        onConfirm={() => {
          removeSurvey(deleteSurvey)
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      >
        You will not be able to recover this survey!
      </SweetAlert>
    </>
  );
};

export default SurveyManagement;
