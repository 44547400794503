// import { Tabs } from "antd";
import { FC, useState } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import RfpProcessDetails from "./RfpProcessDetails";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navContentStyle, navStyle } from "../../../../common/components-style";
import SurveyManagement from "./SurveyManagement";
import QuestionManagement from "./QuestionManagement";
import MYSAReport from "./MYSAReport";
import SubmissionOverview from "./SubmissonOverview";
import InvitationDetail from "./InvitationDetail";
import InviteSelection from "./InviteSelection";


const RfpProcessForm: FC<any> = ({ rfpName, rfpStatus, rfpProcessId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'details');
	const [disableMysaReportTemplate, setDisableMysaReportTemplate] = useState<any>(0);

	return (


		<div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
			{rfpProcessId ? <Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					<Col sm={2}>
						<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="details">RFP Detail</Nav.Link>
								</Nav.Item>
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="invitation">Invitation Detail</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="preferences">Preferences</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="inviteSelection">Invite Selection</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="survey">Survey Management</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="question">Question Management</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item><Nav.Link eventKey="submission">Submission Overview</Nav.Link></Nav.Item>}
								{rfpProcessId && <Nav.Item onClick={() => {
									const count = disableMysaReportTemplate + 1;
									setDisableMysaReportTemplate(count)
								}}><Nav.Link eventKey="mysa">Report</Nav.Link></Nav.Item>}
								{/* {rfpProcessId && <Nav.Item><Nav.Link eventKey="area">Area Report</Nav.Link></Nav.Item>} */}
							</div>
						</Nav>
					</Col>
					<Col sm={10}>
						<Tab.Content >
							<Tab.Pane eventKey="details" >
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <RfpProcessDetails programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							{rfpProcessId && <Tab.Pane eventKey="invitation">
								<div style={navContentStyle}>
									<InvitationDetail programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} />
								</div>
							</Tab.Pane>}
							{rfpProcessId && <Tab.Pane eventKey="preferences">
								<div style={navContentStyle}>
									{/* <SurveyManagement rfpStatus={rfpStatus} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} /> */}
								</div>
							</Tab.Pane>}
							{rfpProcessId && <Tab.Pane eventKey="inviteSelection">
								<div style={navContentStyle}>
								<InviteSelection rfpName={rfpName} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} selectedClientNames={selectedClientName} />
								</div>
							</Tab.Pane>}
							{rfpProcessId && <Tab.Pane eventKey="survey">
								<div style={navContentStyle}>
									<SurveyManagement rfpName={rfpName} rfpStatus={rfpStatus} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} />
								</div>
							</Tab.Pane>}
							{rfpProcessId && <Tab.Pane eventKey="question">
								<div style={navContentStyle}>
									<QuestionManagement rfpName={rfpName} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} />
								</div>
							</Tab.Pane>}
							{rfpProcessId && <Tab.Pane eventKey="submission">
								<div style={navContentStyle}>
									<SubmissionOverview rfpName={rfpName} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} selectedClientNames={selectedClientName} />
								</div>
							</Tab.Pane>}

							{rfpProcessId && <Tab.Pane eventKey="mysa">
								<div style={navContentStyle}>
									<MYSAReport rfpName={rfpName} disableMysaReportTemplate={disableMysaReportTemplate} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} startDate={startDate} endDate={endDate} />
								</div>
							</Tab.Pane>}
							{/* {rfpProcessId && <Tab.Pane eventKey="area">{'Area Report'}
								<div style={navContentStyle}>
									
								</div>
							</Tab.Pane>} */}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container> :
				isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <RfpProcessDetails programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} /> : <AccessDenied />

			}
		</div>
	)
}


export default RfpProcessForm;
