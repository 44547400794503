import { message } from 'antd';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import ProgrammeSpendDataList from './ProgrammeSpendDataList';
import { getStorage } from "../../../common/storage";
import moment from 'moment';
import { UserGroups } from '../../../common/constants';
import { getLoggedUserType } from '../../../common/functions';
import { $mysaBlack, loginButton } from '../../../common/components-style';


import ProgrammeSpendDataForm from './form/ProgrammeSpendDataForm';

const ProgrammeSpendDataHome: FC<any> = ({ location }): JSX.Element => {

	const [selectedClientName, setSelectClientName] = useState<String>("");
	const [programmeName, setProgrammeName] = useState<String>("");
	const [programmeStatus, setProgrammeStatus] = useState<boolean>(false);
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [reportList, setReportList] = useState<[]>([]);
	const [reportId, setReportId] = useState<any>(null);
	const [selectedProgramme, setSelectedProgramme] = useState<any>(null);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [refreshReportList, setRefreshReportList] = useState<boolean>(false);
	const [programmeId, setProgrammeId] = useState<any>(null);
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [accommodationType, setAccommodationType] = useState<any>(null);
	const [reportName, setReportName] = useState<any>(null);


	//actions
	const {
		getCorporateProgrammeById,
		getReportByProgrammeId,
	} = useStoreActions<any>((actions) => ({
		getReportList: actions.booking.getReportList,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		getReportByProgrammeId: actions.booking.getReportByProgrammeId,
	}));

	//state
	const {
		getCorporateProgrammeByIdError,
		getCorporateProgrammeByIdSuccess,
		reportByProgrammeIdSuccess,
		reportByProgrammeIdError,
		reportByProgrammeIdLoading,
	} = useStoreState<any>((state) => ({

		reportByProgrammeIdSuccess: state.booking.reportByProgrammeIdSuccess,
		reportByProgrammeIdError: state.booking.reportByProgrammeIdError,
		reportByProgrammeIdLoading: state.client.reportByProgrammeIdLoading,
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,

	}));

	///////////////////////////////////////////////////////////////////

	const ButtonStyle = {
		...loginButton,
		color: $mysaBlack,
		fontWeight: 'bold'
	} as React.CSSProperties;

	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location) {
			setSelectedClient(location?.state?.selectedClient);
			setProgrammeId(location?.state?.programmeId);
			setProgrammeName(location?.state?.selectedProgramme?.programmeName);
			setSelectedProgramme(location?.state?.selectedProgramme);
		}
	}, [location]);

	/////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (location?.state) {
			setFormOpen(location?.state?.formOpen);
			setReportId(location?.state?.reportId);
			setStartDate(moment(location?.state?.startDate).format('DD MMM YYYY'));
			setEndDate(moment(location?.state?.endDate).format('DD MMM YYYY'));
		}
	}, [location?.state]);


	// to get the selectedProgramme from the localStorage //////////////////////////////////////////////////////
	useEffect(() => {
		const programme = getStorage('myo_selected_programme');
		if (programme) {

			setSelectedProgramme(programme);
		}

	}, []);

	// call getCorporateProgrammeById //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [getCorporateProgrammeById, programmeId]);

	// set state for programme data //////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdError) {
			setProgrammeName(getCorporateProgrammeByIdError?.programmeName)
		}
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { programmeName, isActivated } = getCorporateProgrammeByIdSuccess.data;
			setProgrammeName(programmeName);
			setProgrammeStatus(isActivated);
		}
	}, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError])

	// to fetch  data for the report list //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getReportByProgrammeId(programmeId);
		}
	}, [getReportByProgrammeId, programmeId]
	);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const reload = useCallback(() => {
		setTimeout(() => getReportByProgrammeId(programmeId), 1000);
	}, [getReportByProgrammeId, programmeId])

	// refresh 
	useEffect(() => {
		if (programmeId && refreshReportList) {
			getReportByProgrammeId(programmeId);
			setRefreshReportList(false);
		}
	}, [getReportByProgrammeId, programmeId, refreshReportList]
	);

	//to set the reportListByProgrammeId to local state //////////////////////////////////////////////////////
	useEffect(() => {
		if (reportByProgrammeIdSuccess) {
			setReportList(reportByProgrammeIdSuccess.data);
		}
		if (reportByProgrammeIdError) {
			message.error(reportByProgrammeIdError.message);
		}
	}, [reportByProgrammeIdSuccess, reportByProgrammeIdError, reportByProgrammeIdLoading, reportList])

	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location?.state?.selectedClient?.companyName) {
			setSelectClientName(location?.state?.selectedClient.companyName)
		}
	}, [location]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		if (data !== null) {
			setReportId(data.key);
			setStartDate(moment(data.startDate).format('DD MMM YYYY'));
			setEndDate(moment(data.endDate).format('DD MMM YYYY'));
			setAccommodationType(data.accommodationType);
			setReportName(data.reportName)
		} else {
			setReportId(null);
		}
	}, []);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className='rfp-process-home' >
			<div className="flex justify-between">
				{!location?.state?.selectedClient && <p className="ext-2xl mb-10"></p>}
				{location?.state?.selectedClient && !reportId && <Breadcrumb className='mb-10' >
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/client-management", state: { selectedClient } }} >{selectedClientName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{
							pathname: '/admin/programme-management',
							state: {
								selectedProgramme, selectedClient
							}
						}} >
							{programmeName}
						</Link>
					</Breadcrumb.Item>

					<Breadcrumb.Item active={formOpen ? false : true} >
						{
							formOpen ?
								<Link to={{
									pathname: '/admin/programme-spend-data',
									state: {
										selectedProgramme,
										selectedClient,
										programmeId
									}
								}} >Programme Spend Data</Link> : "Programme Management"
						}
					</Breadcrumb.Item>


					{formOpen && reportId && <Breadcrumb.Item active>
						{startDate}
					</Breadcrumb.Item>}
					{formOpen && !reportId && <Breadcrumb.Item active>
						New Report
					</Breadcrumb.Item>}
				</Breadcrumb>}
				{getLoggedUserType() === UserGroups.super_admin &&
					<Button
						id='edit-button'
						disabled={(programmeStatus === false || (selectedProgramme?.status ? selectedProgramme?.status === 'active' ? false : true : false))}
						hidden={formOpen}
						onClick={() => setFormOpen(true)}
						className="h-10 text-white" >New Report</Button>}

				
			</div>
			{!formOpen ? <ProgrammeSpendDataList setRefreshReportList={setRefreshReportList} loadForm={loadForm} loading={reportByProgrammeIdLoading} reportList={reportList} />
				: <ProgrammeSpendDataForm programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedProgramme={selectedProgramme} selectedClient={selectedClient} startDate={startDate} endDate={endDate} location={location} selectedClientName={selectedClientName} accommodationType={accommodationType} reportName={reportName} />}

		</div>
	)

}

export default ProgrammeSpendDataHome;
