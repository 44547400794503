import { Select } from "antd";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  UserGroups,
  UserAccess,
  BrandStatus,
} from "../../../../common/constants";
import ImageUpload from "../../../common/imageUpload";
import {
  getLoggedUserType,
  canBrandModuleWrite,
  formatPhoneNumber,
} from "../../../../common/functions";
import _ from "lodash";
import GoogleMap from "../../../common/GoogleMap";
import GooglePlaces from "../../../common/GooglePlaces";
import moment from "moment";
import { BrandRegisterFormInput } from "../../../../common/interfaces";
import { formButton, formButton2 } from "../../../../common/components-style";
import DatePicker from "react-datepicker";
import { PhoneNumberUtil } from "google-libphonenumber";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { setStorage } from "../../../../common/storage";

const { Option } = Select;

const BrandDetailsForm: React.FC<any> = ({
  setFormOpen,
  brandId,
  loadData
}): JSX.Element => {
  const [title, setTitle] = useState("");
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<BrandRegisterFormInput>();

  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [brandData, setBrandData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [headOfficeTelephone, setHeadOfficeTelephone] = useState<string>();
  const [mainContactTelephone, setMainContactTelephone] = useState<string>();
  const [auditReference, setAuditReference] = useState<string>();
  const [myoManagementReference, setMyoManagementReference] =
    useState<string>();
  const [image, setImage] = useState(null);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [createdBrandId, setCreatedBrandId] = useState(null);
  // const [email, setEmail] = useState('');
  const [brandStatus, setBrandStatus] = useState("");
  const [userData, setUserData] = useState<any>();
  const [location, setLocation] = useState<any>(null);
  const [coordinates, setCoordinates] = useState<any>(null);
  // const [brandFinanceData, setBrandFinanceData] = useState<any>();
  const [locationDataList, setLocationDataList] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  // const [responsiblePersonEmail, setResponsiblePersonEmail] =
  //   useState<any>(null);
  // const [responsiblePersonName, setResponsiblePersonName] = useState<any>(null);
  // const [brandDomains, setBrandDomains] = useState<Array<string>>([]);
  let [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  // const [show, setShow] = useState(false);

  // const modalHandle = () => setShow(!show);
  const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);
  const [companyList, setCompanyList] = useState<any>([]);
  const [accommodationName, setAccommodationName] = useState<string>('');
  // let validPhoneNumber = false;
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    updateMYOManagementClient,
    onloadBrand,
    onCreateBrand,
    onUpdateBrand,
    onApprovalBrand,
    onReviewBrand,
    createMYOManagementClient,
    createAuditClient,
    updateAuditClient,
    createUserMapping,
    getUserMapping,
    getBrandFinanceDetailByBrandId,
    setLoadingOverlay,
    onGetPartnerList
  } = useStoreActions<any>((actions) => ({
    onloadBrand: actions.brand.onloadBrand,
    onCreateBrand: actions.brand.onCreateBrand,
    onUpdateBrand: actions.brand.onUpdateBrand,
    onApprovalBrand: actions.brand.onApprovalBrand,
    onReviewBrand: actions.brand.onReviewBrand,
    createAuditClient: actions.audit.createAuditClient,
    reAssignCertificate: actions.audit.reAssignCertificate,
    updateAuditClient: actions.audit.updateAuditClient,
    createMYOManagementClient:
      actions.myoManagementModel.createMYOManagementClient,
    updateMYOManagementClient:
      actions.myoManagementModel.updateMYOManagementClient,
    createUserMapping: actions.user.createUserMapping,
    getUserMapping: actions.user.getUserMapping,
    getBrandFinanceDetailByBrandId:
      actions.brand.getBrandFinanceDetailByBrandId,
    setLoadingOverlay: actions.OverlaySpinner.setLoadingOverlay,
    onGetPartnerList: actions.partner.onGetPartnerList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    loadBrandSuccess,
    approvalBrandSuccess,
    reviewBrandSuccess,
    createBrandSuccess,
    createBrandError,
    updateBrandSuccess,
    updateBrandError,
    getUserMappingSuccess,
    getBrandFinanceDetailSuccess,
    approvalBrandError,
    partnerListSuccess,
    partnerListError

  } = useStoreState<any>((state) => ({
    loadBrandSuccess: state.brand.loadBrandSuccess,
    createBrandSuccess: state.brand.createBrandSuccess,
    approvalBrandSuccess: state.brand.approvalBrandSuccess,
    approvalBrandError: state.brand.approvalBrandError,
    reviewBrandSuccess: state.brand.reviewBrandSuccess,
    createBrandError: state.brand.createBrandError,
    updateBrandSuccess: state.brand.updateBrandSuccess,
    updateBrandError: state.brand.updateBrandError,
    getUserMappingSuccess: state.user.getUserMappingSuccess,
    getBrandFinanceDetailSuccess: state.brand.getBrandFinanceDetailSuccess,
    partnerListSuccess: state.partner.partnerListSuccess,
    partnerListError: state.partner.partnerListError,
  }));

  useEffect(() => {
    if (brandId) {
      onloadBrand(brandId);
      getUserMapping({ brandId });
    }
    onGetPartnerList();
  }, [brandId, getUserMapping, onGetPartnerList, onloadBrand]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (updateBrandSuccess) {
      setLoading(false);
      toast.success("Brand updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      scrollToTop();
      setEditEnable(false);
      onloadBrand(updateBrandSuccess?.data?._id);
      loadData(updateBrandSuccess?.data);
      if (auditReference && myoManagementReference) {
        setTimeout(() => {
          /////////// vesta api call ////////////
          updateAuditClient({
            security: {
              apiKey: process.env.REACT_APP_AUDIT_API_KEY,
              user: "superadmin@mysa.global",
            },
            client: {
              id: auditReference,
              parentID: "",
              type: "Brand Client",
              name: updateBrandSuccess?.data?.name,
              contact: userData?.firstName + " " + userData?.lastName,
              email: userData?.email,
              phone: updateBrandSuccess?.data?.headOfficeTelephone,
              externalID: createdBrandId,
              managedExternally: false,
              contactInfo: {
                name: userData?.firstName + " " + userData?.lastName,
                email: userData?.email,
                // name: updateBrandSuccess?.data?.responsiblePersonNameBrandAudit,
                // email: updateBrandSuccess?.data?.responsiblePersonEmail,
              },
            },
          });
        }, 1000);

        //////////////// myo management api call /////////
        updateMYOManagementClient({
          security: {
            apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
            user: "superadmin@mysa.global",
          },
          client: {
            id: myoManagementReference,
            parentID: "",
            type: "Brand Client",
            name: updateBrandSuccess?.data?.name,
            brandStatus: updateBrandSuccess?.data?.brandStatus,
            contact: userData?.firstName + " " + userData?.lastName,
            email: userData?.email,
            phone: updateBrandSuccess?.data?.headOfficeTelephone,
            externalID: createdBrandId,
            managedExternally: false,
            contactInfo: {
              name: userData?.firstName + " " + userData?.lastName,
              email: userData?.email,
              // name: responsiblePersonName,
              // email: responsiblePersonEmail,
            },
          },
        });
      }
    }
  }, [auditReference, createdBrandId, loadData, myoManagementReference, onloadBrand, updateAuditClient, updateBrandSuccess, updateMYOManagementClient, userData?.email, userData?.firstName, userData?.lastName]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (brandId) {
      getBrandFinanceDetailByBrandId(brandId);
    }
  }, [brandId, getBrandFinanceDetailByBrandId]);

  const scrollToTop = () => {
    // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createBrandSuccess) {
      setLoading(false);
      setCreatedBrandId(createBrandSuccess.data.brand._id);
      createUserMapping({
        brandId: createBrandSuccess.data.brand._id,
        userId: createBrandSuccess.data.user.user._id,
        email: createBrandSuccess.data.user.user.email,
        role: UserGroups.brand_admin,
        isFirstUser: false,
      });
      toast.success("Brand created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setEditEnable(false);
      onloadBrand(brandId);
      loadData(updateBrandSuccess?.data);
      setTimeout(() => setFormOpen(false), 3000);
    }

    if (createBrandError) {
      setLoading(false);
      toast.error(createBrandError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (updateBrandError) {
      setLoading(false);
      setLoadingOverlay(false);
      toast.error(updateBrandError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    ////////////////// brand approval and myo management , vesta managing part //////////
    if (approvalBrandSuccess) {
      setLoading(false);
      toast.success("Brand approved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setEditEnable(false);
      onloadBrand(brandId);
      setLoadingOverlay(false);
      // if YES already myo api called to myo management so we need to call again update api. or not we need to call create api
      // if (firstMYOApiCallEnsure === "YES") {
      //   /////////// vesta api call ////////////
      //   updateAuditClient({
      //     security: {
      //       apiKey: process.env.REACT_APP_AUDIT_API_KEY,
      //       user: "superadmin@mysa.global",
      //     },
      //     client: {
      //       id: auditReference,
      //       parentID: "",
      //       type: "Brand Client",
      //       name: watch("name"),
      //       contact: userData?.firstName + " " + userData?.lastName,
      //       email: userData?.email,
      //       phone: phoneNumber,
      //       externalID: createdBrandId,
      //       managedExternally: false,
      //       contactInfo: {
      //         // name: userData?.firstName + " " + userData?.lastName,
      //         // email: userData?.email,
      //         name: responsiblePersonName,
      //         email: responsiblePersonEmail,
      //       },
      //     },
      //   });

      //   //////////////// myo management api call /////////
      //   updateMYOManagementClient({
      //     security: {
      //       apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
      //       user: "superadmin@mysa.global",
      //     },
      //     client: {
      //       id: myoManagementReference,
      //       parentID: "",
      //       type: "Brand Client",
      //       name: watch("name"),
      //       contact: userData?.firstName + " " + userData?.lastName,
      //       email: userData?.email,
      //       phone: phoneNumber,
      //       externalID: createdBrandId,
      //       managedExternally: false,
      //       contactInfo: {
      //         name: userData?.firstName + " " + userData?.lastName,
      //         email: userData?.email,
      //         // name: responsiblePersonName,
      //         // email: responsiblePersonEmail,
      //       },
      //     },
      //   });

      //   setLoading(false);
      //   toast.success("Brand approved successfully", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     className: "foo-bar",
      //   });
      //   setEditEnable(false);
      //   onloadBrand(brandId);
      //   setLoadingOverlay(false);
      // } 
      // else {
      //   /////////// vesta api call ////////////
      //   // setLoading(false);
      //   // setEditEnable(false);
      //   createAuditClient({
      //     security: {
      //       apiKey: process.env.REACT_APP_AUDIT_API_KEY,
      //       user: "superadmin@mysa.global",
      //     },
      //     client: {
      //       id: "",
      //       parentID: "",
      //       type: "Brand Client",
      //       name: watch("name"),
      //       contact: userData?.firstName + " " + userData?.lastName,
      //       email: userData?.email,
      //       phone: phoneNumber,
      //       externalID: createdBrandId,
      //       managedExternally: false,
      //       contactInfo: {
      //         // name: userData?.firstName + " " + userData?.lastName,
      //         // email: userData?.email,
      //         name: responsiblePersonName,
      //         email: responsiblePersonEmail,
      //       },
      //     },
      //   });

      //   createMYOManagementClient({
      //     security: {
      //       apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
      //       user: "superadmin@mysa.global",
      //     },
      //     client: {
      //       id: "",
      //       parentID: "",
      //       type: "Brand Client",
      //       name: watch("name"),
      //       contact: userData?.firstName + " " + userData?.lastName,
      //       email: userData?.email,
      //       phone: phoneNumber,
      //       externalID: createdBrandId,
      //       managedExternally: false,
      //       contactInfo: {
      //         name: userData?.firstName + " " + userData?.lastName,
      //         email: userData?.email,
      //         // name: responsiblePersonName,
      //         // email: responsiblePersonEmail,
      //       },
      //     },
      //   });
      // }
    }

    if (approvalBrandError) {
      setLoading(false);
      setEditEnable(true);
      setLoadingOverlay(false);
    }

    ////////////////// brand reviewed //////////
    if (reviewBrandSuccess) {
      setLoading(false);
      toast.success("Brand reviewed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setEditEnable(false);
      onloadBrand(brandId);
    }

    if (loadBrandSuccess) {
      const {
        name,
        image: imageVal,
        auditReference: ref,
        brandStatus,
        latitude,
        longitude,
        countryCode,
        headOfficeTelephone,
        // responsiblePersonNameBrandAudit,
        //  responsiblePersonEmail,
      } = loadBrandSuccess.data;
      setPhoneNumber(headOfficeTelephone);
      //   setResponsiblePersonName(responsiblePersonNameBrandAudit);
      // setResponsiblePersonEmail(responsiblePersonEmail);
      setBrandData(loadBrandSuccess?.data);
      setCreatedBrandId(loadBrandSuccess?.data?._id);
      // setBrandDomains(loadBrandSuccess.data?.allowedDomains);
      /////////// major fields are direct manage ///////
      setValue("name", loadBrandSuccess.data?.name);
      setStorage('myo_selected_brand', loadBrandSuccess?.data);
      ////////////////////////////////
      setImage(imageVal);
      setTitle(name);
      setAuditReference(ref);
      setMyoManagementReference(loadBrandSuccess.data?.myoManagementReference);
      setBrandStatus(brandStatus);
      setLocation({ lat: Number(latitude), lng: Number(longitude) });
      setLocationDataList({
        lat: latitude,
        lng: longitude,
        countryCode: countryCode,
      });
    }
  }, [approvalBrandError, approvalBrandSuccess, brandId, createBrandError, createBrandSuccess, createUserMapping, loadBrandSuccess, loadData, onloadBrand, reviewBrandSuccess, setFormOpen, setLoadingOverlay, setValue, updateBrandError, updateBrandSuccess?.data]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getUserMappingSuccess) {
      const { result } = getUserMappingSuccess.data;
      const mappedData = _.map(result, (val) => {
        const firstUser =
          _.filter(val.brands, (a) => a.brandId === brandId).shift()
            ?.isFirstUser || false;

        const role = _.filter(val.brands, (a) => a.brandId === brandId).shift()
          ?.role || null;
        if (val.user) {
          return {
            userId: val.user._id,
            firstName: val.user.firstName,
            lastName: val.user.lastName,
            email: val.user.email,
            phone: val.user.phone,
            mobile: val.user.mobile,
            position: val.user.position,
            data: val,
            isFirstUser: firstUser,
            role: role
          };
        }
      });

      let filterData = _.first(
        _.filter(mappedData, (list) => list?.isFirstUser === true && (list?.role === UserGroups.brand_admin || list?.role === UserGroups.chief_brand_admin))
      );
      if (filterData) {
        setUserData(filterData);
      }
    }
  }, [brandId, getUserMappingSuccess]);

  let validatePhoneNumber = (inputNumber: string) => {
    let number = formatPhoneNumber(inputNumber);

    let valid = false;
    let isPossible = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number));
      valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
    } catch (e) {
      valid = false;
    }

    if (isPossible && valid) {
      setValidPhoneNumber(true);
      return true;
    } else {
      setValidPhoneNumber(false);
      return false;
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = (values) => {

    setLoading(true);
    if (!validPhoneNumber) {
      setLoading(false);
      toast.error("Please enter valid phone number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }

    if (coordinates) {
      values.latitude = coordinates.lat.toString();
      values.longitude = coordinates.lng.toString();
    } else {
      values.latitude = location
        ? location?.lat.toString()
        : locationDataList?.lat;
      values.longitude = location
        ? location?.lng.toString()
        : locationDataList?.lng;
      values.countryCode = location
        ? location?.countryCode
        : locationDataList?.countryCode;
    }

    if (brandId) {
      values.id = brandId;
      if (!validatePhoneNumber(values?.headOfficeTelephone)) {
        setLoading(false);
        toast.error("Please enter valid phone number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return;
      }
      if (!validatePhoneNumber(values?.mainContactTelephone)) {
        setLoading(false);
        toast.error("Please enter valid phone number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return;
      }
      const data = {
        id: brandId,
        imageUpdated,
        name: values.name,
        mainContact: values.mainContact,
        description: values.description,
        yearCommencedTrading: moment(values.yearCommencedTrading).add(
          1,
          "days"
        ),
        // totalNumberOfPropertiesWithInventoryUnits:
        //   values.totalNumberOfPropertiesWithInventoryUnits,
        // totalNumberOfManagedInventoryUnits:
        //   values.totalNumberOfManagedInventoryUnits,
        image: image,
        allowedDomains: values.allowedDomains.toString(),

        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country,
        latitude: values.latitude,
        longitude: values.longitude,
        postcode: values.postcode,
        headOfficeTelephone,
        mainContactTelephone,
        website: values.website,
        // responsiblePersonNameBrandAudit: values.responsiblePersonNameBrandAudit,
        // responsiblePersonEmail: values.responsiblePersonEmail,
        accommodationType: values.accommodationType,
        parentAccommodationCompany: values.parentAccommodationCompany
      };
      // setResponsiblePersonName(values.responsiblePersonNameBrandAudit);
      // setResponsiblePersonEmail(values.responsiblePersonEmail);
      setPhoneNumber(mainContactTelephone);
      onUpdateBrand(data);
    } else {
      values.userGroup = UserGroups.user;
      values.image = image;
      values.imageUpdated = imageUpdated;
      values.allowedDomains = values.allowedDomains
        ? values.allowedDomains.toString()
        : "";
      values.brandStatus = BrandStatus.incomplete;
      setPhoneNumber(values.mainContactTelephone);
      //   setResponsiblePersonName(values.responsiblePersonNameBrandAudit);
      //  setResponsiblePersonEmail(values.responsiblePersonEmail);
      onCreateBrand(values);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback(
    (imageBlob) => {
      setImage(imageBlob);
      setImageUpdated(true);
    },
    [setImage]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onAcceptBrand = useCallback(() => {
    setLoading(true);
    if (brandId) {
      const data = {
        id: brandId,
        brandStatus: BrandStatus.accepted,
        client: {
          parentID: "",
          type: "Brand Client",
          name: watch("name"),
          brandStatus: BrandStatus.accepted,
          contact: userData?.firstName + " " + userData?.lastName,
          email: userData?.email,
          phone: phoneNumber,
          externalID: createdBrandId,
          managedExternally: false,
          contactInfo: {
            name: userData?.firstName + " " + userData?.lastName,
            email: userData?.email,
          },
        },
      };
      onApprovalBrand(data);
      setLoadingOverlay(true);
    }
  }, [brandId, createdBrandId, onApprovalBrand, phoneNumber, setLoadingOverlay, userData?.email, userData?.firstName, userData?.lastName, watch]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onReviewedBrand = useCallback(() => {
    setLoading(true);
    if (brandId) {
      const data = {
        id: brandId,
        brandStatus: BrandStatus.review,
      };
      onReviewBrand(data);
    }
  }, [brandId, onReviewBrand]);

  const setCoords = useCallback((coords) => {
    setCoordinates({ lat: coords.lat, lng: coords.lng });
  }, []);

  const setLocationData = useCallback(
    (location) => {
      setValue("country", location?.country);
      setLocation(location);
    },
    [setValue]
  );

  const changeFormEditable = useCallback(() => {
    scrollToTop();
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);


  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // if (partnerListSuccess) {
    const locationListTemp: any = [{
      _id: 'hotel',
      name: "Hotel",
    },
    {
      _id: 'serviced_apartments',
      name: "Extended Stay/Apart hotel",
    },
    {
      _id: 'both',
      name: "Both",
    },
    ];

    const typeOfPartner = locationListTemp.map((val: any, key: number) => {
      return (
        <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
      )
    });

    setAccommodationTypeList(typeOfPartner);
    // }

  }, []);


  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (partnerListSuccess) {
      let companyListTemp: any = [{ _id: '0', name: 'None' }]
      const data = partnerListSuccess.data.filter((user) => user.partnerType.name === 'Parent Accommodation Company');
      data.map(ele => {
        companyListTemp.push(ele);
      })

      const typeOfPartner = companyListTemp.map((val: any, key: number) => {
        return (
          <Option key={key} data={val} value={`${val.name}`} >{val.name}</Option>
        )
      });

      setCompanyList(typeOfPartner);
    }

  }, [partnerListSuccess]);


  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (brandData?.accommodationType === 'serviced_apartments') {
      setAccommodationName('Extended Stay/Apart hotel');
    } else if (brandData?.accommodationType === 'hotel') {
      setAccommodationName('Hotel');
    } else if (brandData?.accommodationType === 'both') {
      setAccommodationName('Both');
    }
  }, [brandData?.accommodationType]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="content-title fixed_title">
            {title}
            <div className="ml-auto">
              {!loading &&
                !editEnable &&
                canBrandModuleWrite(
                  brandId,
                  UserAccess.brand_access.details.code
                ) && (
                  <div className="d-flex align-items-center gx-2">
                    {/* <HelpModal /> */}
                    <Button
                      id="edit-button"
                      style={formButton}
                      className="mb-0"
                      onClick={changeFormEditable}
                    >
                      Edit
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <Container fluid className="p-0">
        <div className="jumbotron" style={{ marginTop: "65px" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Brand name
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && <p className="lead">{brandData?.name}</p>}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.name ? "is-invalid" : ""}`}
                        defaultValue={brandData?.name}
                        {...register("name", { required: true })}
                        readOnly={
                          brandStatus !== "incomplete" &&
                            getLoggedUserType() !== UserGroups.super_admin
                            ? true
                            : false
                        }
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input brand name!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Parent Accommodation Company</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{brandData?.parentAccommodationCompany}</p>}
                    {(editEnable) &&
                      <Controller
                        name="parentAccommodationCompany"
                        defaultValue={brandData?.parentAccommodationCompany}
                        control={control}
                        rules={{
                          required: true,
                        }}

                        render={({ field }) =>
                          <Select disabled={

                            getLoggedUserType() !== UserGroups.super_admin
                              ? true
                              : false
                          }
                            defaultValue={brandData?.parentAccommodationCompany} className={`${errors.parentAccommodationCompany ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                            onChange={(value, option) => {
                              field.onChange(value)
                            }}
                            // onSelect={onTypeSelect}
                            showSearch>
                            {companyList}

                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select a parent Accommodation Company!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Accommodation types operated</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{accommodationName}</p>}
                    {(editEnable) &&
                      <Controller
                        name="accommodationType"
                        defaultValue={brandData?.accommodationType}
                        control={control}
                        rules={{
                          required: true,
                        }}

                        render={({ field }) =>
                          <Select disabled={

                            getLoggedUserType() !== UserGroups.super_admin
                              ? true
                              : false
                          }
                            defaultValue={brandData?.accommodationType} className={`${errors.accommodationType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                            onChange={(value, option) => {
                              field.onChange(value)
                            }}
                            // onSelect={onTypeSelect}
                            showSearch>
                            {accommodationTypeList}


                          </Select>
                        }

                      />}
                    {editEnable && <div className="invalid-feedback">Please select a accommodation type!</div>}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable &&
                        (getLoggedUserType() === UserGroups.super_admin
                          ? false
                          : true) && (
                          <span className="requiredMark">*</span>
                        )}{" "}
                      Brand description
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {/* {!editEnable && <p className="lead">{brandData?.description}</p>}
        {editEnable && <Form.Control as="textarea" rows={5} className={`${errors.description ? 'is-invalid' : ''}`} defaultValue={brandData?.description} {...register("description", { required: getLoggedUserType() === UserGroups.super_admin ? false : true })} readOnly={(brandStatus !== 'incomplete' && getLoggedUserType() !== UserGroups.super_admin) ? true : false} />}
        {editEnable && <div className="invalid-feedback">Please input description!</div>} */}
                    {!editEnable && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: brandData?.description,
                        }}
                      ></div>
                    )}
                    {editEnable && (
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={brandData?.description}
                        rules={{
                          required:
                            getLoggedUserType() === UserGroups.super_admin
                              ? false
                              : true,
                        }}
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              toolbar: {
                                items: ["heading", "|", "undo", "redo"],
                              },
                              heading: {
                                options: [
                                  {
                                    model: "paragraph",
                                    title: "Paragraph",
                                    class: "ck-heading_paragraph",
                                  },
                                ],
                              },
                            }}
                            data={brandData?.description}
                            onChange={(event, editor) => {
                              field.onChange(editor.getData());
                            }}
                            // disabled={!editEnable}
                            readOnly={
                              brandStatus !== "incomplete" &&
                                getLoggedUserType() !== UserGroups.super_admin
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                    )}

                    {editEnable && (
                      <ErrorMessage
                        errors={errors}
                        name="description"
                        render={({ message }) => (
                          <div className="invalid-feedback-custom">
                            Please input description!
                          </div>
                        )}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Year commenced trading
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && brandData?.yearCommencedTrading && (
                      <p className="lead">
                        {moment(brandData?.yearCommencedTrading).format("YYYY")}{" "}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="yearCommencedTrading"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          brandData?.yearCommencedTrading
                            ? new Date(brandData?.yearCommencedTrading)
                            : undefined
                        }
                        render={({ field }) => (
                          <DatePicker
                            className={`form-control ${errors.yearCommencedTrading ? "is-invalid" : ""
                              }`}
                            selected={field.value}
                            dateFormat={"yyyy"}
                            showYearPicker={true}
                            onChange={(date) => field.onChange(date)}
                          />
                        )}
                      />
                    )}
                    {editEnable && (
                      <ErrorMessage
                        errors={errors}
                        name="yearCommencedTrading"
                        render={({ message }) => (
                          <div className="invalid-feedback-custom">
                            Please select year commenced trading!
                          </div>
                        )}
                      />
                    )}
                  </Col>
                </Row>
                {/* <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Total number of properties with inventory units
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {brandData?.totalNumberOfPropertiesWithInventoryUnits}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        min={0}
                        type="number"
                        className={`${errors.totalNumberOfPropertiesWithInventoryUnits
                          ? "is-invalid"
                          : ""
                          }`}
                        defaultValue={
                          brandData?.totalNumberOfPropertiesWithInventoryUnits
                        }
                        {...register(
                          "totalNumberOfPropertiesWithInventoryUnits",
                          { required: true }
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input total number of properties with inventory
                        units!
                      </div>
                    )}
                  </Col>
                </Row> */}
                {/* <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Total number of managed inventory units
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {brandData?.totalNumberOfManagedInventoryUnits}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        min={0}
                        type="number"
                        className={`${errors.totalNumberOfManagedInventoryUnits
                          ? "is-invalid"
                          : ""
                          }`}
                        defaultValue={
                          brandData?.totalNumberOfManagedInventoryUnits
                        }
                        {...register("totalNumberOfManagedInventoryUnits", {
                          required: true,
                        })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input total number of managed inventory units!
                      </div>
                    )}
                  </Col>
                </Row> */}
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>Head office location</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.country}</p>
                    )}
                    {editEnable && (
                      <GooglePlaces
                        onPlaceSelect={(location) => setLocationData(location)}
                      />
                    )}
                    <div style={{ marginTop: "10px" }}></div>
                    <GoogleMap
                      googleMapURL={process.env.REACT_APP_MAP_URL}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `350px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      location={location}
                      setCoords={setCoords}
                    />
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Country
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.country}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.country ? "is-invalid" : ""}`}
                        defaultValue={brandData?.country}
                        {...register("country", {
                          required: location?.country
                            ? false
                            : brandData?.country
                              ? false
                              : true,
                        })}
                        readOnly
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input country!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Address1
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.address1}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.address1 ? "is-invalid" : ""}`}
                        defaultValue={brandData?.address1}
                        {...register("address1", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input address1!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>Address2</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.address2}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        defaultValue={brandData?.address2}
                        {...register("address2")}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      City
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && <p className="lead">{brandData?.city}</p>}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.city ? "is-invalid" : ""}`}
                        defaultValue={brandData?.city}
                        {...register("city", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">Please input city!</div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Postal/Zip code
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.postcode}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.postcode ? "is-invalid" : ""}`}
                        defaultValue={brandData?.postcode}
                        {...register("postcode", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input postal code!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Head office telephone number
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && brandData?.headOfficeTelephone && (
                      <p className="lead">
                        {formatPhoneNumber(brandData?.headOfficeTelephone)}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="headOfficeTelephone"
                        control={control}
                        defaultValue={brandData?.headOfficeTelephone}
                        rules={{
                          required: true,
                          // maxLength: {
                          // 	value: length,
                          // 	message: "Maximum length is " + length + "."
                          // },
                          // minLength: {
                          // 	value: length,
                          // 	message: "Minimum length is " + length + "."
                          // },
                          // validate: () => {
                          // 	if (validPhoneNumber) {
                          // 		return true;
                          // 	}
                          // 	return Promise.reject(toast.error('Please enter valid phone number!', {
                          // 		position: toast.POSITION.BOTTOM_RIGHT,
                          // 		className: 'foo-bar'
                          // 	}));
                          // }
                          // validate: () => validPhoneNumber || 'Please enter valid number'
                        }}
                        render={({ field }) => (
                          <PhoneInput
                            isValid={(inputNumber: string, country: any) => {
                              // const phoneLength = Math.ceil((countries.filter((val: any) => val.dialCode === country.dialCode)[0] as any)?.format.length / 2);
                              // setLength(phoneLength);
                              return validatePhoneNumber(inputNumber);
                            }}
                            containerClass={`${errors.headOfficeTelephone ? "is-invalid" : ""
                              }`}
                            value={String(field.value)}
                            country="gb"
                            onChange={(phone) => {
                              field.onChange(phone);
                              setHeadOfficeTelephone(phone);
                            }}
                            placeholder=""
                          />
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input head office phone number!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Lead brand contact
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.mainContact}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.mainContact ? "is-invalid" : ""}`}
                        defaultValue={brandData?.mainContact}
                        {...register("mainContact", { required: true })}
                        readOnly={
                          brandStatus !== "incomplete" &&
                            getLoggedUserType() !== UserGroups.super_admin
                            ? true
                            : false
                        }
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input brand main contact!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Lead brand telephone number
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && brandData?.mainContactTelephone && (
                      <p className="lead">
                        {formatPhoneNumber(brandData?.mainContactTelephone)}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="mainContactTelephone"
                        control={control}
                        defaultValue={brandData?.mainContactTelephone}
                        rules={{
                          required: true,
                          // maxLength: {
                          // 	value: length,
                          // 	message: "Maximum length is " + length + "."
                          // },
                          // minLength: {
                          // 	value: length,
                          // 	message: "Minimum length is " + length + "."
                          // },
                          // validate: () => {
                          // 	if (validPhoneNumber) {
                          // 		return true;
                          // 	}
                          // 	return Promise.reject(toast.error('Please enter valid phone number!', {
                          // 		position: toast.POSITION.BOTTOM_RIGHT,
                          // 		className: 'foo-bar'
                          // 	}));
                          // }
                          // validate: () => validPhoneNumber || 'Please enter valid number'
                        }}
                        render={({ field }) => (
                          <PhoneInput
                            placeholder=""
                            isValid={(inputNumber: string) => {
                              // const phoneLength = Math.ceil((countries.filter((val: any) => val.dialCode === country.dialCode)[0] as any)?.format.length / 2);
                              // setLength(phoneLength);
                              return validatePhoneNumber(inputNumber);
                            }}
                            containerClass={`${errors.mainContactTelephone ? "is-invalid" : ""
                              }`}
                            value={String(field.value)}
                            country="gb"
                            onChange={(phone) => {
                              field.onChange(phone);
                              setMainContactTelephone(phone);
                            }}
                          />
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input head main contact phone number!
                      </div>
                    )}
                  </Col>
                </Row>

                {/* <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Person responsible for brand audit
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {brandData?.responsiblePersonNameBrandAudit}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.responsiblePersonNameBrandAudit
                          ? "is-invalid"
                          : ""
                          }`}
                        defaultValue={
                          brandData?.responsiblePersonNameBrandAudit
                        }
                        {...register("responsiblePersonNameBrandAudit", {
                          required: true,
                        })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input responsible person name!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Email address
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {brandData?.responsiblePersonEmail}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        type="email"
                        className={`${errors.responsiblePersonEmail ? "is-invalid" : ""
                          }`}
                        defaultValue={brandData?.responsiblePersonEmail}
                        {...register("responsiblePersonEmail", {
                          required: true,
                        })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input responsible person email!
                      </div>
                    )}
                  </Col>
                </Row> */}

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Website
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{brandData?.website}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.website ? "is-invalid" : ""}`}
                        defaultValue={brandData?.website}
                        {...register("website", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input website name!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Brand domains
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {brandData?.allowedDomains.map((value, key: number) => {
                          return <span>{value}, </span>;
                        })}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="allowedDomains"
                        defaultValue={brandData?.allowedDomains}
                        control={control}
                        rules={{
                          required: true,
                          pattern: {
                            value:
                              /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
                            message: "Please enter valid domain name",
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            defaultValue={brandData?.allowedDomains}
                            onChange={(value) => field.onChange(value)}
                            mode="tags"
                            className={`${errors.allowedDomains ? "is-invalid" : ""
                              }`}
                            options={[]}
                            style={{ width: "100%" }}
                            disabled={
                              brandStatus !== "incomplete" &&
                                getLoggedUserType() !== UserGroups.super_admin
                                ? true
                                : false
                            }
                          ></Select>
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please enter valid domain name!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Logo
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <Image width={100} src={String(image)} thumbnail={true} />
                    )}
                    {editEnable && (
                      <Controller
                        name="image"
                        defaultValue={String(image)}
                        control={control}
                        rules={{
                          required: image ? false : true,
                        }}
                        render={({ field }) => (
                          <ImageUpload
                            crop={false}
                            getImageBlob={getImageBlob}
                            type="image"
                            value={String(field.value)}
                            disabled={
                              brandStatus !== "incomplete" &&
                                getLoggedUserType() !== UserGroups.super_admin
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                    )}

                    {editEnable && errors.image && (
                      <div className="invalid-feedback-custom">
                        Please select logo!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}></Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!loading &&
                      !editEnable &&
                      getLoggedUserType() === UserGroups.super_admin &&
                      (brandStatus === "pending" ||
                        brandStatus === "review") && (
                        <Button
                          id="approve-button"
                          style={formButton}
                          onClick={() => {
                            onAcceptBrand();
                          }}
                        >
                          Approve
                        </Button>
                      )}
                    {!loading &&
                      !editEnable &&
                      getLoggedUserType() === UserGroups.super_admin &&
                      brandStatus === "pending" && (
                        <Button
                          id="approve-button"
                          style={formButton2}
                          onClick={() => {
                            onReviewedBrand();
                          }}
                        >
                          Review
                        </Button>
                      )}
                    {!loading &&
                      editEnable &&
                      canBrandModuleWrite(
                        brandId,
                        UserAccess.brand_access.details.code
                      ) &&
                      getLoggedUserType() === UserGroups.super_admin && (
                        <Button
                          type="submit"
                          id="approve-button"
                          style={formButton}
                        >
                          Update
                        </Button>
                      )}
                    {!loading &&
                      editEnable &&
                      canBrandModuleWrite(
                        brandId,
                        UserAccess.brand_access.details.code
                      ) &&
                      getLoggedUserType() !== UserGroups.super_admin && (
                        <Button
                          type="submit"
                          id="approve-button"
                          style={formButton}
                        >
                          Update
                        </Button>
                      )}

                    {loading && (
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="warning" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default BrandDetailsForm;
