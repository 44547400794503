import { Dropdown, Form, Col, Row, Button } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { RfpProcessStatus, UserGroups } from "../../../../common/constants";
import { getLoggedUserType } from "../../../../common/functions";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { RfpProcessCreateFormInput } from "../../../../common/interfaces";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { formButton } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import _ from "lodash";
import { Select } from "antd";
const { Option } = Select;

const RfpProcessDetails: FC<any> = ({ rfpProcessId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme }): JSX.Element => {
	const today = moment(new Date());
	const [loading, setLoading] = useState<boolean>(false);
	// const [clientLocations, setClientLocations] = useState<[]>([]);
	const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
	const [formSelectedEndDate, setFormSelectedEndDate] = useState<any>(null);
	const [formSelectedStartDate, setFormSelectedStartDate] = useState<any>(null);
	const [formSelectedQuestionSubmitDate, setFormSelectedQuestionSubmitDate] = useState<any>(null);
	const [selectedClientName, setSelectedClientName] = useState<string>("");
	const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
	// const [isProcessDeleted, setIsProcessDeleted] = useState<boolean>(false);
	const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
	const [clientId, setClientId] = useState<string>("");
	const [templateId, setTemplateId] = useState<string>("");
	// const [clientDetails, setClientDetails] = useState<any>({});
	const [rfpProcessDetails, setRfpProcessDetails] = useState<any>();
	const [checkBoxValidationMessage, setCheckBoxValidationMessage] = useState<string>("");
	// const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
	// const [disableForm, setDisableForm] = useState<boolean>(false);
	// const [disableQuestionOverviewDatePicker, setDisableQuestionOverviewDatePicker] = useState<boolean>(false);
	const [programLocationsList, setProgramLocationsList] = useState<any>([]);
	const [programmeCities, setProgrammeCities] = useState<any[]>([]);
	const [programmeCountries, setProgrammeCountries] = useState<any[]>([]);
	const [selectedProgrammeLocations, setSelectedProgrammeLocations] = useState<any[]>([]);
	const [selectedProgrammeCountry, setSelectedProgrammeCountry] = useState<string>("");
	const [showCityDropDown, setShowCityDropDown] = useState<boolean>(false);
	// const [overviewForInterview, setOverviewForInterview] = useState<string>("");
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const { register, handleSubmit, reset, formState: { errors }, control } = useForm<RfpProcessCreateFormInput>();
	const [isCityToggleOpen, setIsCityToggleOpen] = useState<boolean>(false);
	const [isAllLocationChecked, setIsAllocationChecked] = useState<boolean>(false);
	const [rfpProcessData, setRfpProcessData] = useState<any>();

	const [selectedCityLocation, setSelectedCityLocation] = useState<any>([]);
	const [locationList, setLocationList] = useState<any>([]);
	const [rfpTypeList, setRfpTypeList] = useState<any>([]);
	const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);

	const {

		createRfpProcess,
		getRfpProcessById,
		getRfpProcessByProgrammeId,
		getCorporateProgrammeById,
		updateRfpProcess,
		getRfpLocationsByProgramId,
		createCertificateTemplate,
		updateCertificateTemplate,
		getClientLocationByProgrammeId
	} = useStoreActions<any>((actions) => ({

		createRfpProcess: actions.rfpProcess.createRfpProcess,
		getRfpProcessById: actions.rfpProcess.getRfpProcessById,
		updateRfpProcess: actions.rfpProcess.updateRfpProcess,
		getRfpProcessByProgrammeId: actions.rfpProcess.getRfpProcessByProgrammeId,
		getRfpLocationsByProgramId: actions.rfpProcess.getRfpLocationsByProgramId,

		getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,

		createCertificateTemplate: actions.myoManagementModel.createCertificateTemplate,
		updateCertificateTemplate: actions.myoManagementModel.updateCertificateTemplate,

	}))

	const {

		rfpProcessByIdError,
		rfpProcessByIdSuccess,
		// deleteRfpProcessLoading,
		// deleteRfpProcessError,
		// deleteRfpProcessSuccess,
		rfpProcessCreationSuccessResponse,
		rfpProcessCreationErrorResponse,
		getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError,
		getClientLocationByProgrammeIdSuccess,
		updateRfpProcessSuccessResponse,
		updateRfpProcessErrorResponse,

		rfpLocationsByProgramIdSuccess,
		// rfpLocationsByProgramIdLoading,
		createCertificateTemplateSuccess,
		updateCertificateTemplateSuccess

	} = useStoreState<any>((state) => ({

		rfpProcessCreationSuccessResponse: state.rfpProcess.createRfpProcessSuccessResponse,
		rfpProcessCreationErrorResponse: state.rfpProcess.createRfpProcessErrorResponse,
		rfpProcessLoadingResponse: state.rfpProcess.createRfpProcessLoadingResponse,

		// deleteRfpProcessSuccess: state.rfpProcess.deleteRfpProcessSuccessResponse,
		// deleteRfpProcessLoading: state.rfpProcess.deleteRfpProcessLoadingResponse,
		// deleteRfpProcessError: state.rfpProcess.deleteRfpProcessErrorResponse,

		rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
		// rfpProcessByIdLoading: state.rfpProcess.rfpProcessByIdLoading,
		rfpProcessByIdError: state.rfpProcess.rfpProcessByIdError,

		rfpLocationsByProgramIdSuccess: state.rfpProcess.rfpLocationsByProgramIdSuccess,
		// rfpLocationsByProgramIdLoading: state.rfpProcess.rfpLocationsByProgramIdLoading,
		rfpLocationsByProgramIdError: state.rfpProcess.rfpLocationsByProgramIdError,

		updateRfpProcessSuccessResponse: state.rfpProcess.updateRfpProcessSuccessResponse,
		updateRfpProcessErrorResponse: state.rfpProcess.updateRfpProcessErrorResponse,

		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,

		getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
		getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,

		createCertificateTemplateSuccess: state.myoManagementModel.createCertificateTemplateSuccess,
		updateCertificateTemplateSuccess: state.myoManagementModel.updateCertificateTemplateSuccess,

	}));

	//////////// myo management
	useEffect(() => {

		if (createCertificateTemplateSuccess) {
			const payload = {
				...rfpProcessData,
				certificateTemplateId: createCertificateTemplateSuccess.templateID
			}
			if (rfpProcessData) {		
				createRfpProcess(payload);
			}
		}
		if (updateCertificateTemplateSuccess) {
			if (updateCertificateTemplateSuccess && rfpProcessData?.id) {
				updateRfpProcess(rfpProcessData);
				setRfpProcessData({});
			}
		}

	}, [createCertificateTemplateSuccess, createRfpProcess, rfpProcessData, updateCertificateTemplateSuccess, updateRfpProcess]);

	//  call locations by programme id ///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getRfpLocationsByProgramId(selectedProgrammeId);
		}
	}, [getRfpLocationsByProgramId, selectedProgrammeId]);


	//  call client locations by programme id ///////////////////////////////////////////////////////////////////////////////////////////////



	const callClientLocationList = useCallback(() => {
		if (selectedProgrammeId) {
			getClientLocationByProgrammeId(selectedProgrammeId);
		}
	}, [getClientLocationByProgrammeId, selectedProgrammeId])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		callClientLocationList();
	}, [callClientLocationList, selectedProgrammeId]);


	useEffect(() => {
		if (rfpLocationsByProgramIdSuccess) {
			setProgramLocationsList(rfpLocationsByProgramIdSuccess?.data);
			const countries = rfpLocationsByProgramIdSuccess?.data.map((item: any) => ({ countryName: item.countryName, countryCode: item.countryCode }))
			setProgrammeCountries(countries);
		}

	}, [rfpLocationsByProgramIdSuccess]);



	useEffect(() => {
		if (getClientLocationByProgrammeIdSuccess) {
			const filterData = _.filter(getClientLocationByProgrammeIdSuccess?.data, value => _.includes(selectedCityLocation, value._id));
			setLocationList(filterData);

		}

	}, [getClientLocationByProgrammeIdSuccess, selectedCityLocation]);

	// ///////////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessId && formSelectedEndDate && formSelectedQuestionSubmitDate) {
			const formattedToday = today.format("YYYY-MM-DD").toString();
			const formattedQuestionSubmittedDate = moment(formSelectedEndDate).add(-1, "day").format("YYYY-MM-DD").toString();
			if (formattedToday === formattedQuestionSubmittedDate) {
				// setDisableQuestionOverviewDatePicker(true);
				// form.setFieldsValue({
				// 	questionSubmitDate: moment(formSelectedEndDate).add(-1, "day")
				// });
			} else {
				// setDisableQuestionOverviewDatePicker(false);
			};
		}
	}, [formSelectedEndDate, formSelectedQuestionSubmitDate, rfpProcessId, today]);

	// activate form disable
	useEffect(() => {
		if (rfpProcessId && today.isAfter(moment(formSelectedStartDate))) {
			// setDisableForm(true);
		}
	}, [formSelectedStartDate, rfpProcessId, today]);

	//hide delete button //////////////////////////////////////////////////////////////////////////////////////////////////////
	const cancelRfp = useCallback(() => {
		const rfpStartDate = moment(formSelectedStartDate);
		if (!today.isAfter(rfpStartDate)) {
			// setShowCancelButton(true);
		}
	}, [formSelectedStartDate, today]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (formSelectedStartDate) cancelRfp();
	}, [cancelRfp, formSelectedStartDate])

	// set the programme id if exists ////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		setSelectedProgrammeId(programmeId);
	}, [programmeId]);

	// set updateRfpProcessSuccessResponse //////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (updateRfpProcessSuccessResponse) {

			setLoading(false);
			// reload();
			toast.success("RFP process updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			if (updateRfpProcessSuccessResponse?.data?._id) {
				getRfpProcessById(updateRfpProcessSuccessResponse?.data?._id);
			}
			setEditEnable(false);
		}
		if (updateRfpProcessErrorResponse) {
			setLoading(false);
			toast.error(updateRfpProcessErrorResponse?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
		}
		setLoading(false);
	}, [getRfpProcessById, updateRfpProcessErrorResponse, updateRfpProcessSuccessResponse]);



	// setting the programme data to the local state ///////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { clientId, programmeName, _id } = getCorporateProgrammeByIdSuccess?.data;
			// setClientDetails(clientId);
			setClientId(clientId._id);
			setSelectedClientName(clientId.companyName);
			setSelectedProgrammeId(_id);
			setSelectedProgrammeName(programmeName);
			if (!rfpProcessId) {
				// setFormSelectedStartDate(moment(new Date()));
			}
		}
		if (getCorporateProgrammeByIdError) {
			toast.error(getCorporateProgrammeByIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getCorporateProgrammeByIdError, getCorporateProgrammeByIdSuccess?.data, rfpProcessId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getCorporateProgrammeById(selectedProgrammeId);
		}
	}, [getCorporateProgrammeById, selectedProgrammeId]);

	// setting the programme id data ////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getRfpProcessByProgrammeId(selectedProgrammeId);
		}
	}, [getRfpProcessByProgrammeId, selectedProgrammeId])

	// to call the api to get the rfp process by id ///////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessId) {
			getRfpProcessById(rfpProcessId);
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [getRfpProcessById, rfpProcessId]);

	// to set the getRfpProcessById response //////////////////////////////////////////////////////////////////////////////////////////


	useEffect(() => {
		if (rfpProcessByIdSuccess?.data) {
			setTemplateId(rfpProcessByIdSuccess?.data?.certificateTemplateId);
			setRfpProcessDetails(rfpProcessByIdSuccess?.data);
			setFormSelectedEndDate(new Date(rfpProcessByIdSuccess?.data.endDate));
			setFormSelectedStartDate(new Date(rfpProcessByIdSuccess?.data.startDate));
			setFormSelectedQuestionSubmitDate(new Date(rfpProcessByIdSuccess?.data.questionSubmitDate));
			// setOverviewForInterview(rfpProcessByIdSuccess?.data.overviewForInterview);

			setIsAllocationChecked(rfpProcessByIdSuccess?.data?.isAllLocationChecked);
			setSelectedProgrammeLocations(rfpProcessByIdSuccess?.data?.locations);

			const cities = rfpProcessByIdSuccess?.data?.locations.map((item: any) => item.cities)
			const citiesArray = [].concat.apply([], cities);
			const cityNames = citiesArray.map((item: any) => item.cityName);
			setSelectedLocations(cityNames);

			let locListTemp: Array<any> = []
			rfpProcessByIdSuccess?.data?.locations.forEach(city => {
				_.map(city.cities, (element) => {
					element.locations.forEach(loc => {
						locListTemp.push(loc);
					})
				})
			})
			setSelectedCityLocation(locListTemp);
			callClientLocationList();
		}
		if (rfpProcessByIdError) {
			toast.error(rfpProcessByIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [callClientLocationList, rfpProcessByIdError, rfpProcessByIdSuccess?.data]);


	// create rfp process logic ////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessCreationErrorResponse) {
			setLoading(false);
			toast.error(rfpProcessCreationErrorResponse?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (rfpProcessCreationSuccessResponse) {
			setLoading(false);
			reload();
			toast.success("RFP process created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			if (rfpProcessCreationSuccessResponse?.data?._id) {
				getRfpProcessById(rfpProcessCreationSuccessResponse?.data?._id);
			}

			setFormOpen(false);
		}
		setLoading(false);
	}, [getRfpProcessById, reload, rfpProcessCreationErrorResponse, rfpProcessCreationSuccessResponse, setFormOpen]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedLocations.length > 0) {
			setCheckBoxValidationMessage("");
		}
	}, [selectedLocations])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onSubmit: SubmitHandler<RfpProcessCreateFormInput> = (values) => {
		setLoading(true);
		if (selectedProgrammeLocations.length === 0) {
			setCheckBoxValidationMessage("Please select at least one location");
		}
		const programmeId = selectedProgrammeId;
		const formattedData = {
			id: rfpProcessDetails?._id,
			clientId,
			programmeId,
			endDate: formSelectedEndDate ? moment(formSelectedEndDate).format("YYYY-MM-DD") : moment(values.endDate).format("YYYY-MM-DD"),
			startDate: moment(values.startDate).format("YYYY-MM-DD"),
			questionSubmitDate: formSelectedQuestionSubmitDate ? moment(formSelectedQuestionSubmitDate).format("YYYY-MM-DD") : moment(values.questionSubmitDate).format("YYYY-MM-DD"),
			locations: selectedProgrammeLocations,
			overviewForInterview: values?.overviewForInterview,
			isAllLocationChecked: isAllLocationChecked ? isAllLocationChecked : isAllLocationChecked,
			rfpType: values.rfpType,
			accommodationType: values.accommodationType,
			rfpName: values.name
		}
	
		// if (values?.startDate && moment(values.startDate).format("YYYY-MM-DD") === (today.format("YYYY-MM-DD"))) {

		// 	const rfpStatus = RfpProcessStatus.INPROGRESS;
		// 	Object.assign(formattedData, { rfpStatus });
		// }
		if (selectedLocations.length !== 0) {
			setRfpProcessData(formattedData);
			const templateData = {
				security: {
					apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
					user: "superadmin@mysa.global"
				},
				template: {
					id: rfpProcessDetails?._id ? templateId : "",
					name: `${selectedClientName} ${selectedProgrammeName} ${moment(formattedData.startDate).format("YYYY-MM-DD").toString()}`,
					description: formattedData.overviewForInterview,
					corporate_client: selectedClientName,
					programme: selectedProgrammeName,
					rfp_date: moment(formattedData.startDate).format("YYYY-MM-DD").toString(),
					rfp_EndDate: moment(formattedData.endDate).format("YYYY-MM-DD").toString(),
					rfpStatus: rfpProcessDetails?.rfpStatus ? rfpProcessDetails?.rfpStatus : RfpProcessStatus.NOTSTARTED,
					useExpectedCompletionPeriod: true,
					expectedCompletionPeriod: 0,
					external: true,
					clientID: process.env.REACT_APP_MYO_CLIENT_ID,
					rfpName:formattedData?.rfpName
				}
			}
			rfpProcessDetails?._id ? updateCertificateTemplate(templateData) : createCertificateTemplate(templateData);

		} else {
			setEditEnable(true);
			setLoading(false);
		}

		// const isLocationSelectionValidated = locationSelectionValidation()
		if (!rfpProcessDetails?._id && selectedLocations.length > 0) {
			// form.resetFields(["startDate", "endDate", "questionSubmitDate", "overviewForInterview", "locations"]);
			setSelectedLocations([]);
			setFormSelectedEndDate(null);
			setFormSelectedQuestionSubmitDate(null);
			reset();
		}
	};

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderClientCountries = useCallback(() => {
		if (programmeCountries) {
			return programmeCountries.map((country, index) => {
				return (
					<Dropdown.Item eventKey={country.countryName} key={index} >{country.countryName}</Dropdown.Item>
				)
			})
		}

	}, [programmeCountries]);

	const handleCountryItemSelect = useCallback((city) => {
		const countryName = selectedProgrammeCountry;
		const countryCode = programmeCountries.filter(country => country.countryName === selectedProgrammeCountry)[0].countryCode;
		const selectedCity = city;
		const locListTemp: Array<any> = selectedCityLocation;
		const objectToSave = {
			countryName,
			countryCode,
			cities: [selectedCity]
		}
		if (selectedProgrammeLocations.length === 0) {
			setSelectedProgrammeLocations([objectToSave]);
			_.map(selectedCity?.locations, (element) => {
				locListTemp.push(element);
			});
			setSelectedCityLocation(locListTemp)
		}
		if (selectedProgrammeLocations.length) {
			if (selectedProgrammeLocations.find(country => country.countryName === countryName)) {
				const index = selectedProgrammeLocations.findIndex(country => country.countryName === countryName);
				// check if city already exists
				if (selectedProgrammeLocations[index].cities.find(city => city === selectedCity)) {
					return;
				}
				selectedProgrammeLocations[index].cities.push(selectedCity);
				_.map(selectedCity?.locations, (element) => {
					selectedCityLocation.push(element)
				});

			} else {
				setSelectedProgrammeLocations([...selectedProgrammeLocations, objectToSave]);
				_.map(selectedCity?.locations, (element) => {
					selectedCityLocation.push(element)
				});
				// setSelectedCityLocation([...selectedCityLocation, city?.locations])
			}
		}

	}, [programmeCountries, selectedCityLocation, selectedProgrammeCountry, selectedProgrammeLocations])



	// handle city remove from the setSelectedProgrammeLocations
	const handleCityRemove = useCallback((cityValue) => {
		let locListTemp: Array<any> = []
		const locList: Array<any> = [];
		// remove the city from the selectedLocations
		const filteredLocations = selectedLocations.filter(location => location !== cityValue);

		let selectedCityCountryName = "";

		// find the country the selected city belongs to from selectedProgrammeLocations
		// const countryName = selectedProgrammeLocations.filter(country => country.cities.find(city => city.cityName === city))[0].countryName;
		selectedProgrammeLocations.forEach(
			country => {
				country.cities.forEach(city => {
					if (String(city.cityName) === String(cityValue)) {
						selectedCityCountryName = country.countryName;
					}
				})
			}
		)
		// find the index of the country from selectedProgrammeLocations to remove the city
		const index = selectedProgrammeLocations.findIndex(country => country.countryName === selectedCityCountryName);
		// remove the city from the selectedProgrammeLocations
		selectedProgrammeLocations[index].cities = selectedProgrammeLocations[index].cities.filter(city => city.cityName !== cityValue);

		// if the country has no cities left, remove the country from selectedProgrammeLocations
		if (selectedProgrammeLocations[index].cities.length === 0) {
			selectedProgrammeLocations.splice(index, 1);

		}

		setSelectedProgrammeLocations([...selectedProgrammeLocations]);

		selectedProgrammeLocations.forEach(city => {
			_.map(city.cities, (element) => {
				element.locations.forEach(loc => {
					locListTemp.push(loc);
				})
			})
		})

		_.map(locListTemp, (element) => {
			locList.push(element)
		})
		setSelectedCityLocation(locList);

		setSelectedLocations(filteredLocations);
	}, [selectedLocations, selectedProgrammeLocations])


	// handle locationValue remove /////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handleLocationRemove = useCallback((locationValue) => {
		const cityListTemp: Array<any> = [];
		const filteredLocations = locationList.filter(location => location._id !== locationValue._id);
		setLocationList(filteredLocations);

		const filterData = _.filter(filteredLocations, value => _.includes(selectedLocations, value.city));
		_.map(filterData, (element) => {
			cityListTemp.push(element.city)
		})
		const cityData = cityListTemp.filter((item, index) => cityListTemp.indexOf(item) === index);
		setSelectedLocations(cityData);
		let selectedCityCountryName = "";
		let selectedCityName = "";
		let selectedCities: any;


		selectedProgrammeLocations.forEach(country => {
			country.cities.forEach(city => {
				if (String(city.cityName) === String(locationValue.city)) {
					selectedCities = country.cities;
					selectedCityCountryName = country.countryName;

				}
			})
		}
		)

		// find the index of the country from selectedProgrammeLocations to remove the city
		const index = selectedProgrammeLocations.findIndex(country => country.countryName === selectedCityCountryName);

		selectedCities.forEach(city => {
			city.locations.forEach(loc => {
				if (String(loc) === String(locationValue._id)) {
					selectedCityName = city.cityName;

				}
			})
		}
		)
		const index1 = selectedCities.findIndex(country => country.cityName === selectedCityName);
		selectedCities[index1].locations = selectedCities[index1].locations.filter(loc => loc !== locationValue._id);

		if (selectedCities[index1].locations.length === 0) {
			selectedCities.splice(index1, 1);
		}
		if (selectedProgrammeLocations[index].cities.length === 0) {
			selectedProgrammeLocations.splice(index, 1);
		}

	}, [locationList, selectedLocations, selectedProgrammeLocations])


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderClientCities = useCallback(() => {
		return programmeCities?.map((city, index) => {
			return (
				<Dropdown.Item onClick={() => handleCountryItemSelect(city)} eventKey={city?.cityName} key={index} >{city?.cityName}</Dropdown.Item>
				// <Checkbox disabled={disableForm} onChange={onEachLocationCheckBoxSelected} value={locationName}>{locationName}{" - "}{city}</Checkbox>

			)
		})
	}, [handleCountryItemSelect, programmeCities]);



	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handleToggle = useCallback((isOpen, event) => {
		if (isOpen) {
			setIsCityToggleOpen(true);

		} else {
			setIsCityToggleOpen(false);
		}

	}, []);


	const handleCountryChange = useCallback((country: any) => {
		setSelectedProgrammeCountry(country);
		setShowCityDropDown(true);
	}, [])


	useEffect(() => {
		if ((selectedProgrammeCountry || programLocationsList)) {
			const selectedCityFullObject = programLocationsList.filter(country => country.countryName === selectedProgrammeCountry);

			setProgrammeCities(selectedCityFullObject[0]?.cities);
		}
	}, [programLocationsList, selectedProgrammeCountry])

	const handleCityChange = useCallback(
		(eventKey: any) => {
			if (eventKey) {

				// check if the eventKey already exists in the selectedLocations array
				const isExist = selectedLocations.find(location => location === eventKey);
				if (!isExist) {
					setSelectedLocations([...selectedLocations, eventKey]);
				}
				callClientLocationList();
			}
		}, [callClientLocationList, selectedLocations]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handleAllLocationsSelect = useCallback((e: any) => {
		setIsAllocationChecked(e.target.checked);
		if (e.target.checked) {
			if (programLocationsList) {
				setSelectedProgrammeLocations(programLocationsList);

				const cities = programLocationsList.map((item: any) => item.cities)
				const citiesArray = [].concat.apply([], cities);
				// get the array of string of city names
				const cityNames = citiesArray.map((item: any) => item.cityName);
				setSelectedLocations(cityNames);
			}
		} else {
			setSelectedLocations([]);
			setSelectedProgrammeLocations([]);
			setLocationList([]);
		}
	}, [programLocationsList]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);


	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'primary',
			name: "Primary",
		}, {
			_id: 'secondary',
			name: "Secondary",
		}];



		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});

		setRfpTypeList(typeOfPartner);
		// }

	}, []);


	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'hotel',
			name: "Hotel",
		},
		{
			_id: 'serviced_apartments',
			name: "Serviced Accommodation",
		},
		{
			_id: 'both',
			name: "Both",
		},
		];



		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});

		setAccommodationTypeList(typeOfPartner);
		// }

	}, []);




	// 	---------------------------------------------------------------------------------------------------
	return (
		<>

			{rfpProcessId &&
				<RFPBreadCrumbPage isPageName="RFP" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpProcessDetails?.rfpName}></RFPBreadCrumbPage>
			}
			<div className="create-update-wrapper" style={{ marginLeft: "40px" }}>
				<Form onSubmit={handleSubmit(onSubmit)} >
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right', }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Company name</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{selectedClientName}</p>}
							{editEnable && <Form.Control className={`${errors.companyName ? 'is-invalid' : ''}`} defaultValue={selectedClientName} {...register("companyName", { required: true })} readOnly={true} />}
							{editEnable && <div className="invalid-feedback">Please input company name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Name of programme</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{selectedProgrammeName}</p>}
							{editEnable && <Form.Control className={`${errors.selectedProgrammeName ? 'is-invalid' : ''}`} defaultValue={selectedProgrammeName} {...register("selectedProgrammeName", { required: true })} readOnly={true} />}
							{editEnable && <div className="invalid-feedback">Please input programme name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Name of RFP</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{rfpProcessDetails?.rfpName}</p>}
							{editEnable && <Form.Control className={`${errors.name ? 'is-invalid' : ''}`} defaultValue={rfpProcessDetails?.rfpName} {...register("name", { required: true })} />}
							{editEnable && <div className="invalid-feedback">Please input rfp name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Start date</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{moment(rfpProcessDetails?.startDate).format('D MMM YYYY')} </p>}
							{
								editEnable &&
								<Controller
									name='startDate'
									control={control}
									rules={{ required: true }}
									defaultValue={(rfpProcessDetails?.startDate) ? new Date(rfpProcessDetails?.startDate) : undefined}
									render={
										({ field }) => {
											return (
												<DatePicker
													dateFormat="d MMM yyyy"
													disabled={rfpProcessDetails ? rfpProcessDetails?.rfpStatus === RfpProcessStatus.NOTSTARTED ? false : true : false}
													placeholderText="Select start date"
													className="form-control"
													selected={field?.value}
													minDate={new Date()}
													onChange={(date) => {
														field.onChange(date);
														setFormSelectedStartDate(date);
													}}
												/>
											)
										}
									}
								/>
							}
							{errors.startDate && <p><span className="form-error" >This field is required</span></p>}
						</Col>
					</Row>


					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} End Date</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{moment(rfpProcessDetails?.endDate).format('D MMM YYYY')} </p>}
							{editEnable && <Controller
								name='endDate'
								control={control}
								rules={{ required: true }}
								defaultValue={(rfpProcessDetails?.endDate) ? new Date(rfpProcessDetails?.endDate) : undefined}
								render={
									({ field }) => {
										return (
											<DatePicker
												dateFormat="d MMM yyyy"
												disabled={rfpProcessDetails ? rfpProcessDetails?.rfpStatus === RfpProcessStatus.NOTSTARTED ? false : true : false}
												placeholderText="Select end date"
												className="form-control"
												selected={field?.value}
												minDate={formSelectedStartDate ? new Date(formSelectedStartDate) : new Date()}
												onChange={(date) => {
													field.onChange(date);
													setFormSelectedEndDate(date);
												}}
											/>
										)
									}
								}
							/>
							}
							{errors.endDate && <p><span className="form-error" >This field is required</span></p>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>}Question submit date</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && rfpProcessDetails?.questionSubmitDate && <p className="lead">{moment(rfpProcessDetails?.questionSubmitDate).format('D MMM YYYY')} </p>}
							{editEnable &&
								<Controller
									name='questionSubmitDate'
									control={control}
									rules={{ required: true }}
									defaultValue={(rfpProcessDetails?.questionSubmitDate) ? new Date(rfpProcessDetails?.questionSubmitDate) : undefined}
									render={
										({ field }) => {
											return (
												<DatePicker
													dateFormat="d MMM yyyy"
													disabled={rfpProcessDetails ? rfpProcessDetails?.rfpStatus === RfpProcessStatus.NOTSTARTED ? false : true : false}
													placeholderText="Select submit date"
													minDate={new Date(formSelectedStartDate)}
													maxDate={new Date(formSelectedEndDate)}
													className="form-control"
													selected={field?.value}
													onChange={(date) => {
														field.onChange(date);
														setFormSelectedQuestionSubmitDate(date);
													}}
												/>
											)
										}}
								/>
							}
							{errors.questionSubmitDate && <p><span className="form-error" >This field is required</span></p>}
						</Col>
					</Row>

					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Type of RFP</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{rfpProcessDetails?.rfpType}</p>}
							{editEnable &&
								<Controller
									name="rfpType"
									defaultValue={rfpProcessDetails?.rfpType}
									control={control}
									rules={{
										required: true,
									}}
									render={({ field }) =>
										<Select defaultValue={rfpProcessDetails?.rfpType} className={`${errors.rfpType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
											onChange={(value, option) => {
												field.onChange(value)
											}}
											// onSelect={onTypeSelect}
											showSearch>
											{rfpTypeList}
										</Select>
									}
								/>}
							{editEnable && <div className="invalid-feedback">Please select a rfp type!</div>}
						</Col>
					</Row>

					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Accommodation type</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{rfpProcessDetails?.accommodationType}</p>}
							{editEnable &&
								<Controller
									name="accommodationType"
									defaultValue={rfpProcessDetails?.accommodationType}
									control={control}
									rules={{
										required: true,
									}}
									render={({ field }) =>
										<Select defaultValue={rfpProcessDetails?.accommodationType} className={`${errors.accommodationType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
											onChange={(value, option) => {
												field.onChange(value)
											}}
											// onSelect={onTypeSelect}
											showSearch>
											{accommodationTypeList}
										</Select>
									}
								/>}
							{editEnable && <div className="invalid-feedback">Please select a accommodation type!</div>}
						</Col>
					</Row>
					{/* <Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>}Invite Overview</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{rfpProcessDetails?.overviewForInterview}</p>}

							{editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a overview"} className={`${errors.overviewForInterview ? 'is-invalid' : ''}`} defaultValue={rfpProcessDetails?.overviewForInterview} {...register("overviewForInterview", { required: true })} />}
							{editEnable && <div className="invalid-feedback">Please input overview!</div>}
						</Col>
					</Row> */}

					{(!editEnable) && <Row style={{
						marginBottom: '30px'
					}} className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label></Form.Label>
						</Col>
						<Col hidden={isAllLocationChecked === false} md={2} style={{ textAlign: 'left', marginTop: '8px' }} >
							<Form.Group controlId="formBasicCheckbox">
								<Form.Check disabled type="checkbox" label="All locations" checked={isAllLocationChecked} onChange={handleAllLocationsSelect} name="isAllLocationChecked" />
							</Form.Group>
						</Col>
					</Row>}

					{editEnable && <Row style={{
						marginBottom: '30px'
					}} className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label></Form.Label>
						</Col>
						<Col md={2} style={{ textAlign: 'left', marginTop: '8px' }} >
							<Form.Group controlId="formBasicCheckbox">
								<Form.Check type="checkbox" label="All locations" checked={isAllLocationChecked} onChange={handleAllLocationsSelect} name="isAllLocationChecked" />
							</Form.Group>
						</Col>
					</Row>}

					{editEnable && <Row className='formSpace'>
						<Col hidden={isAllLocationChecked} md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>}Country</Form.Label>
						</Col>

						<Col hidden={isAllLocationChecked} md={3} style={{ textAlign: 'left', minHeight: '100px', }}>
							<Dropdown
								onSelect={handleCountryChange}
								drop="down"
								onToggle={handleToggle}
							>
								<Dropdown.Toggle className={isCityToggleOpen ? "toggle-selected" : ""} id="dropdown-basic">
									{selectedProgrammeCountry ? selectedProgrammeCountry : "Select"}
								</Dropdown.Toggle>
								<Dropdown.Menu >
									{renderClientCountries()}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col hidden={!showCityDropDown || isAllLocationChecked} md={2} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>}City</Form.Label>
						</Col>

						<Col hidden={!showCityDropDown || isAllLocationChecked} md={2} style={{ textAlign: 'left', minHeight: '100px' }}>
							<Dropdown
								onSelect={handleCityChange}
								drop="down"
								onToggle={handleToggle}
							>
								<Dropdown.Toggle className={isCityToggleOpen ? "toggle-selected" : ""} id="dropdown-basic">
									Select
								</Dropdown.Toggle>
								<Dropdown.Menu >
									{renderClientCities()}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						{
							<p className="form-error text-center">{checkBoxValidationMessage}</p>
						}
					</Row>}

					{selectedLocations &&
						<Row hidden={isAllLocationChecked}>
							<Col md={3} className="mt-2" style={{ textAlign: 'right' }}>
								<Form.Label>Selected cities</Form.Label>
							</Col>

							<Col md={3} style={{ marginTop: '4px' }} >
								{
									selectedLocations?.map((location, index) => <div key={index} className="form-control"  ><span>{location}{" "}</span>
										{editEnable && <span onClick={() => handleCityRemove(location)} style={{ color: 'red', cursor: 'pointer' }}>&#10005;</span>}
									</div>)
								}
							</Col>


							<Col md={2} className="mt-2" style={{ textAlign: 'right' }}>
								<Form.Label>Selected locations</Form.Label>
							</Col>

							<Col md={4} style={{ marginTop: '4px' }} >
								{
									locationList?.map((location, index) => <div key={index} className="form-control"  ><span>{location.locationName} {" - "}{location.city} {" - "} {location.country}{" "}</span>
										{editEnable && <span onClick={() => handleLocationRemove(location)} style={{ color: 'red', cursor: 'pointer' }}>&#10005;</span>}
									</div>)
								}
							</Col>
						</Row>
					}

					<Row style={{ paddingTop: '30px' }} className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>

						</Col>
						<Col md={3} >
							{getLoggedUserType() === UserGroups.super_admin && !loading && editEnable && rfpProcessId && <Button id='create-button' type="submit">{'Update'}</Button>}
							{getLoggedUserType() === UserGroups.super_admin && !loading && !rfpProcessId && <Button id='create-button' className="btn_design" type="submit"
							>{'Create'}
							</Button>}
							{
								!loading && !editEnable && rfpProcessId && rfpProcessDetails?.rfpStatus === RfpProcessStatus.NOTSTARTED && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
							}

							{/* {
								loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
							} */}

						</Col>
					</Row>

				</Form>
			</div>

		</>
	)

}

export default RfpProcessDetails;
