import { FC, useState } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import ProgrammeSpendDetails from "./ProgrammeSpendDetails";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navContentStyle, navStyle } from "../../../../common/components-style";
import UploadPropertyData from "./UploadPropertyData";
import UnmatchedPropertyData from "./UnmatchedPropertyData";


const ProgrammeSpendDataForm: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName, accommodationType, reportName }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'createReport');

	return (


		<div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
			{reportId ? <Tab.Container defaultActiveKey="createReport" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					<Col sm={2}>
						<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="createReport">Create Report</Nav.Link>
								</Nav.Item>
								{reportId && <Nav.Item><Nav.Link eventKey="uploadProperties">Upload Properties</Nav.Link></Nav.Item>}
								{reportId && <Nav.Item><Nav.Link eventKey="unmatchedProperties">Unmatched Properties</Nav.Link></Nav.Item>}
								{reportId && <Nav.Item><Nav.Link eventKey="uploadData">Upload Data</Nav.Link></Nav.Item>}
								{reportId && <Nav.Item><Nav.Link eventKey="reportDataFields">Report Data Fields</Nav.Link></Nav.Item>}
								{reportId && <Nav.Item><Nav.Link eventKey="locationReporting">Location Reporting</Nav.Link></Nav.Item>}
							</div>
						</Nav>
					</Col>
					<Col sm={10}>
						<Tab.Content >
							<Tab.Pane eventKey="createReport" >
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ProgrammeSpendDetails programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							{reportId && <Tab.Pane eventKey="uploadProperties">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <UploadPropertyData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{reportId && <Tab.Pane eventKey="unmatchedProperties">
								<div style={navContentStyle}>
								{isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <UnmatchedPropertyData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{reportId && <Tab.Pane eventKey="uploadData">
								<div style={navContentStyle}>
								</div>
							</Tab.Pane>}
							{reportId && <Tab.Pane eventKey="reportDataFields">
								<div style={navContentStyle}>
								</div>
							</Tab.Pane>}
							{reportId && <Tab.Pane eventKey="locationReporting">
								<div style={navContentStyle}>
								</div>
							</Tab.Pane>}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container> :
				isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ProgrammeSpendDetails programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} /> : <AccessDenied />
			}
		</div>
	)
}


export default ProgrammeSpendDataForm;
