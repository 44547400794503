import { Col, Nav, Row, Tab } from 'react-bootstrap';
import React, { useState, useCallback, useEffect } from 'react';
import BrandForm from './form/BrandForm';
import BrandList from './BrandList';
import { getStorage, setStorage } from '../../../common/storage';

import LoadingOverlay from 'react-loading-overlay-ts';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getLoggedUserType } from '../../../common/functions';
import { UserGroups } from '../../../common/constants';
import { navContentStyle, navStyle, tabDivStyle } from '../../../common/components-style';
import BrandPropertyManagement from '../property-management/brand-property-management/BrandPropertyManagement';
import BrandHotelManagement from '../hotel-management/brand-hotel-management/BrandHotelManagement';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


const NewBrandHomePage: React.FC<any> = ({ location }): JSX.Element => {
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [brandInvitation, setBrandInvitation] = useState<boolean>(false);
    const [brandId, setBrandId] = useState<number | null>(null);
    const [extId, setExtId] = useState<number | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<any>(null);
    const [tabKey, setTabKey] = useState<any>('brand');
    const [brandCount, setBrandCount] = useState<number>(0);
    const [propertyCount, setPropertyCount] = useState<number>(0);
    const [hotelCount, setHotelCount] = useState<number>(0);
    const [applicationCount, setApplicationCount] = useState<number>(0);
    const [invitationCount, setInvitationCount] = useState<number>(0);
    const history = useHistory<any>();
    /**
     * navigate
     */


    /**
     * global status
     */
    // const loading = useStoreState<any>((state) => state.OverlaySpinner.getLoadingOverlay);

    useEffect(() => {
        if (history?.location?.state?.tabKey) {
            setTabKey(history?.location?.state?.tabKey);
        }
        if (location?.state) {
            setFormOpen(location?.state?.formOpen);
            setBrandInvitation(location?.state?.brandInvite);
            setBrandId(location?.state?.brandId);
        }
    }, [history, location?.state]);

    const { getBrandListNew, getAllPropertiesList, onLoadBrandSignUpList } = useStoreActions<any>((actions) => ({
        getBrandListNew: actions.brand.getBrandListNew,
        getAllPropertiesList: actions.property.getAllPropertiesList,
        onLoadBrandSignUpList: actions.brand.onLoadBrandSignUpList,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getBrandListNewSuccess, getBrandListNewError, loading, getAllPropertiesListSuccess, loadBrandSignUpListSuccess, getAllHotelsSuccess } = useStoreState<any>((state) => ({
        getBrandListNewSuccess: state.brand.getBrandListNewSuccess,
        getBrandListNewError: state.brand.getBrandListNewError,
        loading: state.OverlaySpinner.getLoadingOverlay,
        getAllPropertiesListSuccess: state.property.getAllPropertiesListSuccess,
        getAllHotelsSuccess: state.hotel.getAllHotelsSuccess,
        loadBrandSignUpListSuccess: state.brand.loadBrandSignUpListSuccess,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const callBrandList = useCallback(() => {
        const payload = {
            brand_id: "",
            brand_status: "pending",
            property_status: "",
        }
        getBrandListNew(payload);
    }, [getBrandListNew]);

    useEffect(() => {
        // setTimeout(() => callBrandList(), 1000);
        callBrandList()
    }, [callBrandList]);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandListNewError) {
            toast.error(getBrandListNewError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (getBrandListNewSuccess) {
            const count = getBrandListNewSuccess?.data;
            setBrandCount(count.length);

        }

    }, [getBrandListNewError, getBrandListNewSuccess]);



    const getLoadAllProperties = useCallback(() => {
        const payload = {
            page: 1,
            perPage: 10,
            brand: "",
            city: "",
            status: "pending",
            country: ""
        };
        getAllPropertiesList(payload);
    }, [getAllPropertiesList])

    useEffect(() => {
        // setTimeout(() => getLoadAllProperties(), 1000);
        getLoadAllProperties()
    }, [getLoadAllProperties]);



    useEffect(() => {

        if (getAllPropertiesListSuccess?.data) {
            setPropertyCount(getAllPropertiesListSuccess?.data?.total);

        }
    }, [getAllPropertiesListSuccess]);

    useEffect(() => {

        if (getAllHotelsSuccess?.data) {
            setHotelCount(getAllHotelsSuccess?.data?.total);

        }
    }, [getAllHotelsSuccess]);


    //////////////////////////////////////////// get all brand sign up //////////////////////////////////
    useEffect(() => {
        const payload = {
            start_date: '',
            end_date: '',
            brand_id: '',
            sign_up_status: 'pending',
        }
        onLoadBrandSignUpList(payload);
    }, [onLoadBrandSignUpList]);

    //////////////////////////////////////// customize data without accepted status and invite create and success manage //////////////////////////////////////
    useEffect(() => {
        if (loadBrandSignUpListSuccess) {

            setInvitationCount(loadBrandSignUpListSuccess.data?.length)
        }
    }, [loadBrandSignUpListSuccess]);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const loadForm = useCallback((id, extId) => {
        const brand = getStorage('myo_selected_brand');
        setSelectedBrand(brand);
        setFormOpen(true);
        setBrandId(id);
        setExtId(extId);
    }, []);


    const loadData = useCallback((data) => {
        setSelectedBrand(data);
        setBrandId(data?._id);
        setFormOpen(true);
        setBrandInvitation(false);
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if ((location.state?.allInOnePropertyBreadCrumb || location.state?.allInOneHotelBreadCrumb) && location.state?.tab) {
            setTabKey(location.state?.tab);
            setFormOpen(false);
        }
        if (location.state?.selectedBrand && !location.state?.allInOnePropertyBreadCrumb && !location.state?.allInOneHotelBreadCrumb) {
            loadForm(location.state?.selectedBrand.key?location.state?.selectedBrand.key:location.state?.selectedBrand._id, location.state?.selectedBrand?.extId);
        }
        if (location.state?.brandInvitation) {
            setBrandInvitation(location.state?.brandInvitation);
        }
    }, [loadForm, location]);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const clearStore = useCallback(() => {
        setStorage('myo_selected_brand', null);
        setSelectedBrand(null);
    }, []);

    /////////////////////////////////////// manage brand invitation display ///////////////////////////
    const manageBrandInvitationDisplay = useCallback((value) => {
        setBrandInvitation(value);
    }, []);

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Please wait whilst the system configures...'
        >
            <div>


                {/* <div className='p-relative'> */}
                {/* {
                        !formOpen && !brandInvitation && getLoggedUserType() === UserGroups.super_admin && <div style={{ fontWeight: 'bold', cursor: 'pointer', position: 'absolute', marginLeft: '12%', marginTop: '8px', fontSize: '13px' }} onClick={() => {
                            push('/admin/brand-property-management');
                        }}>Change to Property Management</div>
                    } */}
                {/* <div>
                    {
                        brandInvitation && <>
                            <div>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <Link to={{ pathname: "/admin/brand-management" }} onClick={() => { manageBrandInvitationDisplay(false); setFormOpen(false); }}>Brand Management </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>Brand Invitation</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </>
                    }
                </div> */}
                {getLoggedUserType() !== UserGroups.super_admin && <div className='brandInvite_wrapper'>
                    {!formOpen && !brandInvitation && <BrandList loadForm={loadForm} manageBrandInvitationDisplay={manageBrandInvitationDisplay} />}
                    {formOpen && !brandInvitation && <BrandForm loadData={loadData} brandId={brandId} extId={extId} clearStore={clearStore} setFormOpen={setFormOpen} location={location} selectedBrand={selectedBrand} />}
                    {/* {brandInvitation && <BrandInvitationList />} */}
                    {/* </div> */}

                </div>}


                {

                    getLoggedUserType() === UserGroups.super_admin && !formOpen &&
                    <div style={tabDivStyle}>
                        <Tab.Container defaultActiveKey="brand" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                            <Row >
                                <Col sm={2}>
                                    <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                        <div style={{ marginTop: '0%' }}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="brand">Brands {'(' + brandCount + ')'}</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="invitations">Invitations {'(' + invitationCount + ')'}</Nav.Link>
                                            </Nav.Item> */}

                                            <Nav.Item>
                                                <Nav.Link eventKey="applications">Applications {'(' + applicationCount + ')'}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="properties">Serviced Accom {'(' + propertyCount + ')'}</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link eventKey="hotels">Hotels {'(' + hotelCount + ')'}</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                    </Nav>
                                </Col>

                                <Col sm={10}>
                                    <Tab.Content >
                                        <Tab.Pane eventKey="brand" >
                                            <div style={navContentStyle}>
                                                {/* <div className='p-relative'>
                                                    {
                                                        !formOpen && !brandInvitation && getLoggedUserType() === UserGroups.super_admin && <div style={{ fontWeight: 'bold', cursor: 'pointer', position: 'absolute', marginLeft: '14%', marginTop: '2px', fontSize: '13px' }} onClick={() => {
                                                            push('/admin/brand-property-management');
                                                        }}>Change to Property Management</div>
                                                    }
                                                </div> */}
                                                {!formOpen && !brandInvitation && <BrandList loadForm={loadForm} manageBrandInvitationDisplay={manageBrandInvitationDisplay} />}


                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="properties" >
                                            <div style={navContentStyle}>
                                                <BrandPropertyManagement></BrandPropertyManagement>
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="invitations" >
                                            <div style={navContentStyle}>
                                                <BrandInvitationList></BrandInvitationList>
                                            </div>
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="hotels" >
                                            <div style={navContentStyle}>
                                                <BrandHotelManagement></BrandHotelManagement>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>

                }
                {getLoggedUserType() === UserGroups.super_admin && formOpen && !brandInvitation && <BrandForm loadData={loadData} setSelectedBrand={setSelectedBrand} brandId={brandId} extId={extId} clearStore={clearStore} setFormOpen={setFormOpen} location={location} selectedBrand={selectedBrand} />}

            </div>
        </LoadingOverlay>
    )
};

export default NewBrandHomePage;