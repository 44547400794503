import React, { useCallback, useEffect, useState } from "react"
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, selectStyles, selectStylesLittleRect, tableStyles } from "../../../../common/components-style";
import { Breadcrumb, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import RestoreEdit from '../../../../images/restore.png';
import { HotelAuditStatus, HotelStatus } from "../../../../common/constants";
import { ProgressBar, MagnifyingGlass } from 'react-loader-spinner'
import Select from 'react-select';
import { Link, useHistory } from "react-router-dom";
import { setStorage } from "../../../../common/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import Archive from "../../../../images/icons/svg/outside/Icon-Function-Archive.svg";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const BrandHotelManagement: React.FunctionComponent = (location) => {

	/**
	 * global action
	 */
	const { getAllHotels, updateHotel } = useStoreActions<any>((actions) => ({
		getAllHotels: actions.hotel.getAllHotels,
		updateHotel: actions.hotel.updateHotel,
	}));

	/**
	 * global state
	 */
	const { getAllHotelsSuccess, updateHotelSuccess } = useStoreState<any>((state) => ({
		getAllHotelsSuccess: state.hotel.getAllHotelsSuccess,
		updateHotelSuccess: state.hotel.updateHotelSuccess,
	}));

	/**
	 * local state
	 */
	const [data, setData] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [totalRows, setTotalRows] = useState(0);
	const [firstLoading, setFirstLoading] = useState<boolean>(true);
	const [brandsOption, setBrandsOption] = useState<any>([]);
	const [countriesOption, setCountriesOption] = useState<any>([]);
	const [citiesOption, setCitiesOption] = useState<any>([]);
	const [brand, setBrand] = useState<string>('');
	const [country, setCountry] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [status, setStatus] = useState<string>('');
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(50);
	const [archivePopop, setArchivePopop] = useState<boolean>(false);
	const [hotelArchive, setHotelArchive] = useState<any>("");
	const [reinstatePopop, setReinstatePopop] = useState<boolean>(false);
	const [reinstateArchive, setReinstateArchive] = useState<any>("");

	/**
	 * navigate
	 */
	const { push } = useHistory();

	/**
	 * loadApi
	 */

	const getLoadAllHotels = useCallback(() => {
		const payload = {
			page: page,
			perPage: perPage,
			brand: brand,
			city: city,
			status: status,
			country: country
		};
		getAllHotels(payload);
	}, [brand, city, country, getAllHotels, page, perPage, status])

	useEffect(() => {
		setTimeout(() => getLoadAllHotels(), 500);

	}, [brand, city, country, getLoadAllHotels, page, perPage, status]);

	/**
	 * response 
	 */

	useEffect(() => {

		if (getAllHotelsSuccess?.data) {
			setData(getAllHotelsSuccess.data.hotels);
			setFirstLoading(false);
			setTableLoading(false);
			setLoading(false);
			setTotalRows(getAllHotelsSuccess.data.total);
			/**
			 * set brands
			 */
			const brandListTemp: any = [];
			for (const brand of getAllHotelsSuccess.data.brands) {
				brandListTemp.push({
					value: brand?._id,
					label: brand?.name,
				})
			}
			setBrandsOption(brandListTemp);

			/**
			 * set countries
			 */
			const countryListTemp: any = [];
			for (const country of getAllHotelsSuccess?.data?.countries) {
				countryListTemp.push({
					value: country?._id,
					label: country?._id,
				})
			}
			setCountriesOption(countryListTemp);

			/**
			 * set cities
			 */
			const citiesListTemp: any = [];
			for (const city of getAllHotelsSuccess?.data?.cities) {
				citiesListTemp.push({
					value: city?._id,
					label: city?._id,
				})
			}
			setCitiesOption(citiesListTemp);
		}

		if (updateHotelSuccess) {
			getLoadAllHotels();
		}

	}, [brand, city, country, getAllHotelsSuccess, getLoadAllHotels, status, updateHotelSuccess]);

	/**
	 * define column
	 */
	const columns = [
		{
			name: 'Brand',
			selector: row => row.brand.name,
		},
		{
			name: 'Hotel',
			selector: row => row.hotelName,
		},
		{
			name: 'Country',
			selector: row => row.country,
		},
		{
			name: 'City',
			selector: row => row.city,
		},
		{
			name: 'Status',
			cell: (row) => {
				let color = "";
				if (row.hotelStatus === HotelStatus.pending) color = $mysaYellow;
				if (row.hotelStatus === HotelStatus.approved) color = $mysaTeal;
				if (row.hotelStatus === HotelStatus.awaiting) color = $mysaMegenta;
				if (row.hotelStatus === HotelStatus.review) color = $mysaYellow;
				if (row.hotelStatus === HotelStatus.archived) color = $mysaMegenta;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.hotelStatus}</span>
				)
			}
		},
		{
			name: 'Audit Status',
			cell: (row) => {
				let color = "";
				let text = "";
				if (row.auditStatus === HotelAuditStatus.NOT_STARTED) {
					color = $mysaMegenta;
					text = 'Not Started';
				};
				if (row.auditStatus === HotelAuditStatus.CERTIFIED) {
					color = $mysaTeal;
					text = 'Certified';
				};
				if (row.auditStatus === HotelAuditStatus.IN_PROGRESS) {
					color = $mysaYellow;
					text = 'In progress';
				};
				if (row.auditStatus === HotelAuditStatus.PENDING_APPROVAL) {
					color = $mysaYellow;
					text = "Pending Approval";
				}
				if (row.auditStatus === HotelAuditStatus.AWAITING_APPROVAL) {
					color = $mysaYellow;
					text = "Awaiting Approval";
				}
				if (row.auditStatus === HotelAuditStatus.APPROVE) {
					color = $mysaTeal;
					text = "Approved";
				}
				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{text}</span>
				)
			}
		},
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {

				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => {

							const brand = {
								key: row.brand._id,
								extId: null,
								image: row.brand.image,
								name: row.brand.name,
								brandStatus: row.brand.brandStatus,
								countPending: row.brand.countPending,
								countIncomplete: row.brand.countIncomplete
							}
							setStorage('myo_selected_brand', brand);

							push(`/admin/hotel-management`, {
								allInOneHotel: row,
								allInOneHotelBreadCrumb: true
							});
						}} />
					</OverlayTrigger>
					{
						row.hotelStatus === HotelStatus.archived && row.brand.brandStatus !== 'active' && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Reinstate</Tooltip>}
						>
							<img className='iconSizes' src={RestoreEdit} alt="" onClick={() => {
								const data = {
									id: row?._id,
									hotelStatus: HotelStatus.pending,
								};
								setReinstateArchive(data);
								setReinstatePopop(true);
							}} />
						</OverlayTrigger>
					}
					{
						row.hotelStatus !== HotelStatus.archived && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id="tooltip-table-top">Archive</Tooltip>}
						>
							<img
								className="iconSizes"
								src={Archive}
								alt=""
								onClick={() => {
									const data = {
										id: row?._id,
										hotelStatus: HotelStatus.archived,
									};
									setHotelArchive(data);
									setArchivePopop(true);
								}}
							/>
						</OverlayTrigger>
					}

				</>)
			},
		},
	];

	/**
	 * @description set page dynamically
	 * @param page 
	 */
	const handlePageChange = page => {
		setTableLoading(true);
		setPage(page);
	};

	/**
	 * @description set page and perPage dynamically
	 * @param perPage 
	 * @param page 
	 */
	const handlePerRowsChange = async (perPage, page) => {
		setTableLoading(true);
		setPage(page);
		setPerPage(perPage);
	};

	/**
	 * update reinstate status
	 */
	// const onHotelReinstate = useCallback(
	// 	(hotel) => {
	// 		updateHotel(hotel);
	// 		setTableLoading(true);
	// 	},
	// 	[updateHotel]
	// );

	// /**
	//  * update state
	//  */
	// const onHotelArchive = useCallback(
	// 	(hotel) => {
	// 		updateHotel(hotel);
	// 	},
	// 	[updateHotel]
	// );

	return (<>

		{
			!firstLoading && <>
				<div>
					<Breadcrumb>
						<Breadcrumb.Item active>
							<Link to={{ pathname: "/admin/brand-hotel-management" }} >Hotel Management </Link>
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>

			</>
		}

		{
			firstLoading && <>
				<div style={{ marginLeft: '45%', marginTop: '10%' }}>
					<ProgressBar
						visible={true}
						height="140"
						width="140"
						ariaLabel="MagnifyingGlass-loading"
						wrapperStyle={{}}
						wrapperClass="MagnifyingGlass-wrapper"
						borderColor='#fAC90E'
						barColor='#212121'
					/>
				</div>
				<div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', marginLeft: '0px', marginTop: '-20px' }}>Switching to hotel management...</div>
			</>
		}

		{
			!firstLoading && <>
				<Row className='btn_wrapper mt-5'>
					<Col xs={2}>
						<Select
							className='search_bar select_btn'
							isClearable
							placeholder={<div>Search Brand</div>}
							options={brandsOption}
							styles={selectStylesLittleRect}
							isSearchable={true}
							onChange={(value: any) => { setBrand(value?.value ? value?.value : ''); setLoading(true); }}
						/>
					</Col>
					<Col xs={1}>
						<div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Country</b>
						</div>
					</Col>
					<Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={countriesOption}
							styles={selectStyles}
							isSearchable={false}
							onChange={(value: any) => { setCountry(value?.value ? value?.value : ''); setLoading(true); }}
						/>
					</Col>
					<Col xs={2}>
						<div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Hotel Status</b>
						</div>
					</Col>
					<Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={[
								{
									value: HotelStatus.awaiting,
									label: 'Incomplete',
								},
								{
									value: HotelStatus.approved,
									label: 'Complete',
								},
								{
									value: HotelStatus.review,
									label: 'Review',
								},
								{
									value: HotelStatus.pending,
									label: 'Pending',
								},
								{
									value: HotelStatus.archived,
									label: 'Archived',
								}
							]}
							styles={selectStyles}
							isSearchable={false}
							onChange={(value: any) => { setStatus(value?.value ? value?.value : ''); setLoading(true); }}
						/>
					</Col>
				</Row>
				<Row className='btn_wrapper mt-4'>
					<Col xs={2}>
					</Col>
					<Col xs={1}>
						<div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>City</b>
						</div>
					</Col>
					<Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={citiesOption}
							styles={selectStyles}
							isSearchable={false}
							onChange={(value: any) => { setCity(value?.value ? value?.value : ''); setLoading(true); }}
						/>
					</Col>
					<Col xs={2}>
					</Col>
					<Col xs={2}>
						<div style={{ marginLeft: '35%', }}>
							<MagnifyingGlass
								visible={loading}
								height="80"
								width="80"
								ariaLabel="MagnifyingGlass-loading"
								wrapperStyle={{}}
								wrapperClass="MagnifyingGlass-wrapper"
								glassColor='#c0efff'
								color='#fAC90E'
							/>
						</div>
					</Col>
				</Row>
			</>
		}


		{
			!firstLoading && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
				<DataTable
					columns={columns}
					data={data}
					progressPending={tableLoading}
					pagination
					paginationServer
					striped={true}
					customStyles={tableStyles}
					theme='mysaTable'
					fixedHeader={false}
					paginationTotalRows={totalRows}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					noDataComponent={'Updating records'}
					paginationPerPage={50}
					paginationRowsPerPageOptions={[10, 50, 100, 200]}
				/>
			</div>
		}

		<SweetAlert
			show={reinstatePopop}
			warning
			showCancel={true}
			focusCancelBtn={true}
			confirmBtnText="Yes reinstate it!"
			confirmBtnBsStyle="danger"
			title="Are you sure you wish to reinstate this hotel?"
			onConfirm={() => {
				// onHotelReinstate(reinstateArchive);
				setReinstatePopop(false);
			}}
			onCancel={() => {
				setReinstatePopop(false);
			}}
		></SweetAlert>

		<SweetAlert
			show={archivePopop}
			warning
			showCancel={true}
			focusCancelBtn={true}
			confirmBtnText="Yes archive it!"
			confirmBtnBsStyle="danger"
			title="Are you sure you wish to archive this hotel?"
			onConfirm={() => {
				// onHotelArchive(hotelArchive);
				setArchivePopop(false);
			}}
			onCancel={() => {
				setArchivePopop(false);
			}}
		></SweetAlert>
	</>);
};

export default BrandHotelManagement;